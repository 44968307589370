import { getEnvironmentString } from "@/libs/environment";
import posthog from "posthog-js";

if (typeof window !== "undefined") {
  const environment = getEnvironmentString();

  if (environment === "production" || environment === "staging") {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://app.posthog.com",
      autocapture: false,
    });
  }
}
