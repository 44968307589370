import { Invite, Member, Role, unknownUser } from "@/tenant/model";
import { QueryFunction, useIsMutating, useQuery } from "react-query";
import { useIsPublic, useTenantIdFromUrl } from "@/libs/hooksLib";

import { AutomaticUpdatesSettings } from "@/updates/models";
import { ContentVersion } from "@/documentVersions/models";
import { DocumentPermissionMap } from "@/libs/permissions";
import { Objective } from "@/objectives/models";
import { TenantOverviews } from "@/user/model";
import { differenceInDays } from "date-fns";
import { post } from "@/libs/awsLib";
import { shallow } from "zustand/shallow";
import { theme } from "@/theme";
import { useTransitionStore } from "@/store/transitionStore";
import { useUserStore } from "@/user/userStore";

export enum Plan {
  Starter = "Starter",
  Growth = "Growth",
  Enterprise = "Enterprise",
}

export enum Coupon {
  Partner = "Partner discount",
  PreSeed = "Pre-seed launch",
}

export interface ChartSettings {
  startDate: Date;
  endDate: Date;
  interval: string;
  chartType: string;
}

export interface QuickbooksPermissions {
  allowedUsers: DocumentPermissionMap;
  allowedInvitedUsers: DocumentPermissionMap;
  allowedGroups: DocumentPermissionMap;
}

export interface SocialMediaSettings {
  twitter?: {
    connected: boolean;
    handle: string;
  };
  facebook?: {
    connected: boolean;
    handle: string;
  };
  linkedIn?: {
    connected: boolean;
    companies: any;
    handle: string;
  };
}

type QueryKey = ["list tenant", string, string];

export interface RefreshReturn {
  id: string;
  name: string;
  legalName: string;
  logo: string;
  primaryLogo: string;
  brandColor: string;
  groups: string[];
  objectives?: Objective[];
  objectivesHistory?: ContentVersion[];
  members: Member[];
  invites: Invite[];
  createTimestamp?: Date;
  subscription?: string;
  status?: "active" | "trial" | "exempt" | "expired";
  plan?: Plan;
  trialEnd?: Date;
  coupon?: Coupon;
  automaticUpdatesSettings?: AutomaticUpdatesSettings;
  quickbooksSettings?: {
    connected?: boolean;
    showFinancials?: boolean;
    permissions?: QuickbooksPermissions;
    profitAndLoss?: ChartSettings;
    totalCash?: ChartSettings;
  };
  socialMediaSettings?: SocialMediaSettings;
  isImportAdmin?: boolean;
  suggestedEmails?: string[];
}

export const refresh: QueryFunction<RefreshReturn, QueryKey> = async ({
  queryKey,
}) => {
  const tenantId = queryKey[1];
  const tenant = await post("/tenant", { api: "get", tenantId });
  const members = [];
  for (const i in tenant.Members) {
    members.push({
      ...tenant.Members[i],
      groups: tenant.Members[i].groups || [],
      lastActiveTime: new Date(tenant.Members[i].lastActiveTimestamp),
    });
  }
  const invites = tenant.Invites.map((i: any) => {
    return {
      name: i.FullName,
      email: i.EmailAddress,
      role: i.Role,
      inviteTime: new Date(i.CreateTimestamp),
    };
  }).sort((a: any, b: any) => b.inviteTime.getTime() - a.inviteTime.getTime());
  const permissions = tenant.QuickbooksSettings?.Permissions;
  const profitAndLoss = tenant.QuickbooksSettings?.ProfitAndLoss;
  const totalCash = tenant.QuickbooksSettings?.TotalCash;

  return {
    id: tenant.TenantId,
    name: tenant.TenantName || "",
    legalName: tenant.TenantLegalName || "",
    primaryLogo: tenant.PrimaryLogo,
    brandColor: tenant.BrandColor || theme.palette.secondary.main,
    logo: tenant.Logo,
    groups: tenant.Groups || [],
    objectives: tenant.Objectives,
    objectivesHistory: tenant.ObjectivesHistory,
    members,
    invites,
    automaticUpdatesSettings: {
      socialMediaPosts: {
        cadence: {
          type: tenant.AutomaticUpdatesSettings.socialMediaPosts.cadence.type,
          startFrom: tenant.AutomaticUpdatesSettings.socialMediaPosts.cadence
            .startFrom
            ? new Date(
                tenant.AutomaticUpdatesSettings.socialMediaPosts.cadence.startFrom,
              )
            : null,
        },
      },
      objectives: {
        cadence: {
          type: tenant.AutomaticUpdatesSettings.objectives.cadence.type,
          startFrom: tenant.AutomaticUpdatesSettings.objectives.cadence
            .startFrom
            ? new Date(
                tenant.AutomaticUpdatesSettings.objectives.cadence.startFrom,
              )
            : null,
        },
      },
    },
    createTimestamp: new Date(tenant.CreateTimestamp),
    subscription: tenant.Subscription,
    status: tenant.Status,
    plan: tenant.Plan,
    trialEnd: new Date(tenant.TrialEnd),
    coupon: tenant.Coupon,
    quickbooksSettings: {
      connected: tenant.QuickbooksSettings?.Connected,
      showFinancials: tenant.QuickbooksSettings?.ShowFinancials,
      permissions: {
        allowedUsers: permissions?.allowedUsers || {},
        allowedInvitedUsers: permissions?.allowedInvitedUsers || {},
        allowedGroups: permissions?.allowedGroups || {},
      },
      profitAndLoss: profitAndLoss && {
        ...profitAndLoss,
        startDate: new Date(profitAndLoss.startDate),
        endDate: new Date(profitAndLoss.endDate),
      },
      totalCash: totalCash && {
        ...totalCash,
        startDate: new Date(totalCash.startDate),
        endDate: new Date(totalCash.endDate),
      },
    },
    socialMediaSettings: {
      twitter: {
        connected: tenant.SocialMediaSettings?.Twitter?.connected,
        handle: tenant.SocialMediaSettings?.Twitter?.handle,
      },
      facebook: {
        connected: tenant.SocialMediaSettings?.Facebook?.connected,
        handle: tenant.SocialMediaSettings?.Facebook?.handle,
      },
      linkedIn: {
        connected: tenant.SocialMediaSettings?.LinkedIn?.connected,
        companies: tenant.SocialMediaSettings?.LinkedIn?.companies,
        handle: tenant.SocialMediaSettings?.LinkedIn?.handle,
      },
    },
    isImportAdmin: tenant.IsImportAdmin,
    suggestedEmails: tenant.SuggestedEmails,
  };
};

export const publicRefresh: QueryFunction<RefreshReturn, QueryKey> = async ({
  queryKey,
  signal,
}) => {
  const tenantId = queryKey[1];
  const tenant = await post("/public", {
    api: "get_tenant",
    tenantId,
    signal,
  });
  return {
    ...tenant,
    groups: [],
    invites: [],
    quickbooksSettings: {},
  };
};

const useSelect = <T>(
  select: (data: RefreshReturn) => T,
  options?: { staleTime?: number },
) => {
  const tenantId = useTenantIdFromUrl();
  const isMutating = useIsMutating(["tenant", { pauseQueries: true }]) > 0;
  const isPublic = useIsPublic();
  const isTransitioning = useTransitionStore((state) => state.transition);
  const isUserAuthLoading = useUserStore((state) => state.loading);
  const isAuthenticated = useUserStore((state) => state.isAuthenticated);
  const userTenants = useUserStore((state) => state.user?.tenants);
  const userInvites = useUserStore((state) => state.user?.invites);
  const tenantDoesNotBelongToUser =
    !userTenants?.find((t) => t && t.TenantId === tenantId) &&
    !userInvites?.find((i) => i.TenantId === tenantId);

  return useQuery(
    ["list tenant", tenantId],
    isPublic || !isAuthenticated() ? publicRefresh : refresh,
    {
      select,
      initialData: {
        id: tenantId,
        name: "",
        legalName: "",
        primaryLogo: "",
        brandColor: "",
        logo: "",
        groups: [],
        members: [],
        invites: [],
        quickbooksSettings: {},
        socialMediaSettings: {},
        objectivesHistory: [],
      },
      enabled:
        !!tenantId &&
        !tenantDoesNotBelongToUser &&
        !isMutating &&
        !isUserAuthLoading &&
        !isTransitioning,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export const AllowedAdmins = {
  [Plan.Starter]: 2,
  [Plan.Growth]: 5,
  [Plan.Enterprise]: NaN,
};

export const AllowedEditors = {
  [Plan.Starter]: 5,
  [Plan.Growth]: 15,
  [Plan.Enterprise]: NaN,
};

export function useAllottedAdminsAndEditors() {
  const plan = usePlan();
  return {
    admins: AllowedAdmins[plan],
    editors: AllowedEditors[plan],
  };
}

export function useFreeTrialDaysLeft() {
  const { trialEnd } = useTenant().data;
  const date = new Date();
  return differenceInDays(trialEnd, date) + 1;
}

export function useTenant() {
  return useSelect((data) => data);
}

export function usePlan() {
  return useSelect((data) => data.plan).data;
}

export function useSubscription() {
  return useSelect((data) => data.subscription).data;
}

export function useStatus() {
  return useSelect((data) => data.status).data;
}

export function useMembers() {
  return useSelect((data) => data.members).data;
}

export function useMembersLength() {
  return useSelect(
    (data) => data?.members && data?.members.filter((m) => !m.former).length,
  ).data;
}

export function useCurrentMembers() {
  return useSelect((data) => data.members.filter((m) => !m.former)).data;
}

export function useInvites() {
  return useSelect((data) => data.invites).data;
}

export function useTenantId() {
  return useSelect((data) => data.id).data;
}

export function useTenantName() {
  return useSelect((data) => data.name).data;
}

export function useTenantLegalName() {
  return useSelect((data) => data.legalName).data;
}

export function useTenantLogo() {
  return useSelect((data) => data.logo).data;
}

export function useTenantPrimaryLogo() {
  return useSelect((data) => data.primaryLogo).data;
}

export function useTenantBrandColor() {
  return useSelect((data) => data.brandColor || theme.palette.secondary.main)
    .data;
}

export function useTenantAutomaticUpdates() {
  return useSelect((data) => data.automaticUpdatesSettings).data;
}

export function useTenantGroups() {
  return useSelect((data) => data.groups).data as string[];
}

export function useTenantObjectives() {
  return useSelect((data) => data.objectives).data;
}

export function useQuickbooksSettings() {
  return useSelect((data) => data.quickbooksSettings).data;
}

export function useSocialMediaSettings() {
  return useSelect((data) => data.socialMediaSettings).data;
}

export function useUrlTenantId() {
  return makeUrlTenantId(useTenantIdFromUrl(), useTenantName());
}

export function makeUrlTenantId(id: string, tenantName: string) {
  const name = makeNameUrlSafe(tenantName);
  const isOldId = (id?.match(/-/g) || []).length !== 0;
  return !isOldId && name ? `${name}-${id}` : id;
}

export function makeNameUrlSafe(name: string) {
  return name
    ?.replace(/[!@#$%^&*())_+-=[\]{}|;':",.<>?/\\']/g, "")
    ?.replace(/ /g, "_");
}

export function useMember(userId: string) {
  return useSelect((data) => {
    if (typeof userId !== "string") return unknownUser;
    const member = data.members.find((member) => member.id === userId);
    return member || unknownUser;
  }).data as Member;
}

/**
 * @returns whether current user is an admin
 */
export function useIsAdmin() {
  const userId = useUserStore((state) => state.user?.id, shallow);
  const { role } = useMember(userId);
  return role === Role.Admin;
}

export function useIsImportAdmin() {
  return useSelect((data) => data.isImportAdmin).data;
}

export function useIsCollaborator() {
  const userId = useUserStore((state) => state.user?.id, shallow);
  const { role } = useMember(userId);
  return role === Role.Collaborator;
}

export function useGetCurrentUserRole() {
  const userId = useUserStore((state) => state.user?.id, shallow);
  const { role } = useMember(userId);
  return role;
}

export function useGetCurrentUserGroups() {
  const userId = useUserStore((state) => state.user?.id, shallow);
  const { groups } = useMember(userId);
  return groups;
}

export function useIsAdminOrCollaborator() {
  const isAdmin = useIsAdmin();
  const isCollaborator = useIsCollaborator();
  return isAdmin || isCollaborator;
}

export function useCanCreate() {
  //Previously equal to useIsAdminOrCollaborator
  //Currently all roles have create access
  return true;
}

export async function create(subscription?: string) {
  return await post("/tenant", { api: "create", subscription });
}

export async function refreshAllTenantOverviews() {
  return await post("/tenant", {
    api: "get_overviews",
  });
}

const useWorkspaceOverviewsSelect = <T>(
  select: (data: TenantOverviews) => T,
  options?: { staleTime?: number },
) => {
  const userId = useUserStore((state) => state.user?.id, shallow);
  return useQuery("overviews", refreshAllTenantOverviews, {
    select,
    initialData: {},
    refetchOnMount: ({ state }) => Object.keys(state.data).length === 0,
    refetchOnWindowFocus: false,
    enabled: !!userId,
    ...options,
  });
};

export const useWorkspaceOverviews = () => {
  return useWorkspaceOverviewsSelect((data) => data).data;
};

export const useWorkspaceOverview = (tenantId: string) => {
  return useWorkspaceOverviewsSelect(
    (data) =>
      data[tenantId] || {
        Tasks: 0,
        Mentions: 0,
        Notifications: 0,
        Updates: 0,
        Asks: 0,
        Meetings: 0,
        Approvals: 0,
        Members: 0,
      },
  );
};

export const useGroupMembers = () => {
  const members = useCurrentMembers();
  const allGroups = useTenantGroups();
  return Object.fromEntries([
    ...allGroups.map((group) => [
      group,
      members.filter((m) => m.groups.includes(group)),
    ]),
    ["Members without groups", members.filter((m) => !m.groups.length)],
  ]) as { [group: string]: Member[] };
};
