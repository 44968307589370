import React, { useState } from "react";
import { useIsDvhSupported, useIsMobile } from "@/libs/hooksLib";

import { theme } from "@/theme";
import { useMediaQuery } from "@mui/material";

type ContextProps = {
  stackedLayout: boolean;
  bannerHeight: number;
  headerHeight: number;
  pageOffset: number;
  pageHeight: string;
  setBannerOpen: (val: boolean) => any;
};

const BannerContext = React.createContext<ContextProps | null>(null);

interface Props {
  children: React.ReactNode;
}

export const BannerProvider: React.FunctionComponentFactory<Props> = ({
  children,
}) => {
  const [bannerOpen, setBannerOpen] = useState(false);
  const isDvhSupported = useIsDvhSupported();
  const isMobile = useIsMobile();
  const stackedLayout = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: false,
  }); // Banner can only be open after first render, so media query can stay
  const bannerHeight = bannerOpen ? (stackedLayout ? 44 : 36) : 0;
  const headerHeight = isMobile ? 57 : 65; //Includes 1px border
  const pageOffset = bannerHeight + headerHeight;
  const pageHeight = isDvhSupported
    ? `calc(100dvh - ${headerHeight + bannerHeight}px)`
    : `calc(100vh - ${headerHeight + bannerHeight}px)`;

  return (
    <BannerContext.Provider
      value={{
        stackedLayout,
        bannerHeight,
        headerHeight,
        pageOffset,
        pageHeight,
        setBannerOpen,
      }}
    >
      {children}
    </BannerContext.Provider>
  );
};

export function useBanner() {
  return React.useContext(BannerContext);
}
