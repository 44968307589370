import { useEffect, useRef } from "react";
import { useIsAuthenticated, useUserStore } from "@/user/userStore";

import { Auth } from "@aws-amplify/auth";
import config from "@/config";
import { create } from "zustand";
import { createId } from "@/libs/misc";
import { shallow } from "zustand/shallow";
import useWebSocket from "react-use-websocket";

export interface WebsocketStore {
  hasNewMessage: boolean;
  setHasNewMessage: (hasNewMessage: boolean) => void;
  paused: { [type: string]: boolean };
  messageHandlers: any[];
  addMessageHandler: (
    type: string,
    callback: (message: any) => any,
    priority?: number,
  ) => string;
  removeMessageHandler: (handlerId: string) => void;
  pauseWebsocket: (type: string) => void;
  resumeWebsocket: (type: string) => void;
}

export const useWebsocketStore = create<WebsocketStore>((set) => ({
  hasNewMessage: false,
  setHasNewMessage: (hasNewMessage) => set({ hasNewMessage }),
  paused: {
    discussion: false,
    approval: false,
    meeting: false,
  },
  messageHandlers: [],
  addMessageHandler: (type, callback, priority = 999) => {
    const id = createId();
    set((state) => {
      const updatedHandlers = [
        ...state.messageHandlers,
        { id, type, callback, priority },
      ].sort((a, b) => a.priority - b.priority);
      return {
        messageHandlers: updatedHandlers,
      };
    });
    return id;
  },
  removeMessageHandler: (handleId) =>
    set((state) => {
      const updatedHandlers = state.messageHandlers.filter(
        (h) => h.id !== handleId,
      );
      return {
        messageHandlers: updatedHandlers,
      };
    }),

  pauseWebsocket: (type) =>
    set((state) => {
      const updatedPaused = { ...state.paused, [type]: true };
      return {
        paused: updatedPaused,
      };
    }),

  resumeWebsocket: (type) =>
    set((state) => {
      const updatedPaused = { ...state.paused, [type]: false };
      return {
        paused: updatedPaused,
      };
    }),
}));

export const useAddMessageHandler = () => {
  const addMessageHandlerRef = useRef(
    useWebsocketStore.getState().addMessageHandler,
  );

  useEffect(() => {
    useWebsocketStore.subscribe(
      (state) => (addMessageHandlerRef.current = state.addMessageHandler),
    );
  }, []);

  return (type: string, callback: (message: any) => any, priority = 999) => {
    return addMessageHandlerRef.current(type, callback, priority);
  };
};

export const useRemoveMessageHandler = () => {
  const removeMessageHandlerRef = useRef(
    useWebsocketStore.getState().removeMessageHandler,
  );

  useEffect(() => {
    useWebsocketStore.subscribe(
      (state) => (removeMessageHandlerRef.current = state.removeMessageHandler),
    );
  }, []);

  return removeMessageHandlerRef.current;
};

export const usePauseWebsocket = () => {
  const pauseWebsocketRef = useRef(useWebsocketStore.getState().pauseWebsocket);

  useEffect(() => {
    useWebsocketStore.subscribe(
      (state) => (pauseWebsocketRef.current = state.pauseWebsocket),
    );
  }, []);

  return pauseWebsocketRef.current;
};

export const useResumeWebsocket = () => {
  const resumeWebsocketRef = useRef(
    useWebsocketStore.getState().resumeWebsocket,
  );

  useEffect(() => {
    useWebsocketStore.subscribe(
      (state) => (resumeWebsocketRef.current = state.resumeWebsocket),
    );
  }, []);

  return resumeWebsocketRef.current;
};

async function buildUrl(): Promise<string> {
  try {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const wssUrl = `${config.websocketUrl}?token=${token}`;
    return wssUrl;
  } catch (error) {
    console.warn(
      "Error building websocket url... Returning unsigned websocket url",
      error,
    );
    return config.websocketUrl;
  }
}

export const useWebsocketListener = () => {
  const userEmail = useUserStore((state) => state.user?.email, shallow);
  const isAuthenticated = useIsAuthenticated();
  const setHasNewMessage = useWebsocketStore((state) => state.setHasNewMessage);
  const hasNewMessage = useWebsocketStore((state) => state.hasNewMessage);
  const paused = useWebsocketStore((state) => state.paused);
  const messageHandlers = useWebsocketStore((state) => state.messageHandlers);

  const { lastMessage } = useWebSocket(
    buildUrl,
    {
      shouldReconnect: (_e) => true,
      reconnectAttempts: 20,
      reconnectInterval: 5000,
      onError: (e) => {
        console.debug("useWebsocket Error:", e);
        console.info("useWebsocket user:", userEmail);
      },
    },
    isAuthenticated(),
  );

  useEffect(() => {
    setHasNewMessage(true);
  }, [lastMessage, setHasNewMessage]);

  useEffect(() => {
    if (hasNewMessage) {
      setHasNewMessage(false);
      if (lastMessage) {
        let message = JSON.parse(lastMessage.data);
        if (!paused[message.entity]) {
          (async () => {
            const handlers = messageHandlers.filter(
              (h) => h.type === message.type,
            );
            for (let i = 0; i < handlers.length; i++) {
              message = (await handlers[i].callback(message)) || message;
            }
          })();
        }
      }
    }
  }, [hasNewMessage, lastMessage, messageHandlers, paused, setHasNewMessage]);
};
