import { Typography } from "@mui/material";
import { defaultFont } from "@/theme";

export function Text(props: any) {
  return (
    <Typography
      variant="body1"
      style={{ fontFamily: defaultFont }}
      gutterBottom
    >
      {props.children}
    </Typography>
  );
}
