import * as Sentry from "@sentry/nextjs";

import React, { useEffect } from "react";
import { useIsMac, useIsMobile } from "@/libs/hooksLib";
import {
  useListenForCreditsConfetti,
  useLoadUserFromCurrentSession,
} from "@/user/hooks";
import { useTenantId, useTenantName } from "@/tenant/queries";

import { Box } from "@mui/material";
import { MuseoSans } from "@/theme";
import SurfbreakPage from "@/components/SurfbreakPage";
import { Transition } from "@/transition/Transition";
import clsx from "clsx";
import { getEnvironmentString } from "@/libs/environment";
import { isExcludedAnalyticsUser } from "@surfboard/lib";
import { shallow } from "zustand/shallow";
import { shouldDisplayIntercom } from "@/lib/analytics/identify";
import { useCookies } from "react-cookie";
import { useInitGmailApi } from "@/store/gmailApiStore";
import { useInitNavigationStore } from "@/store/navigationStore";
import { useIntercom } from "react-use-intercom";
import { usePostHog } from "posthog-js/react";
import { useRouter } from "next/router";
import { useUserStore } from "@/user/userStore";
import { useWebsocketListener } from "@/user/websocketStore";

interface AppWrapperProps {
  children: React.ReactNode;
}

const AppWrapper: React.FunctionComponentFactory<AppWrapperProps> = ({
  children,
}) => {
  const isMac = useIsMac();
  const router = useRouter();
  const isMobile = useIsMobile();
  const userEmail = useUserStore((state) => state.user?.email, shallow);
  const userName = useUserStore((state) => state.user?.name, shallow);
  const workspaceId = useTenantId();
  const workspaceName = useTenantName();
  const posthog = usePostHog();
  const environment = getEnvironmentString();

  // Remove legacy cookies. If you see these lines in 2025 then DELETE.
  const [, , removeCookie] = useCookies([
    "surfboardFeatureFlags",
    "plan",
    "invite",
    "email",
  ]);

  useEffect(() => {
    // End legacy cookie removal code.
    removeCookie("surfboardFeatureFlags", { path: "/" });
    removeCookie("plan", { path: "/" });
    removeCookie("invite", { path: "/" });
    removeCookie("email", { path: "/" });
  }, [removeCookie]);

  const {
    boot: startIntercom,
    shutdown: stopIntercom,
    update: updateIntercom,
    hide: hideIntercom,
  } = useIntercom();

  useEffect(() => {
    // always hide by default when first loading the app
    hideIntercom();
    updateIntercom({
      hideDefaultLauncher: true,
    });

    const isAllowedUser = !isExcludedAnalyticsUser(
      userEmail,
      getEnvironmentString(),
    );

    if (
      isAllowedUser &&
      !isMobile &&
      !process.env.NEXT_PUBLIC_DISABLE_INTERCOM
    ) {
      startIntercom();
    } else {
      stopIntercom();
    }

    () => {
      stopIntercom();
    };
  }, [
    hideIntercom,
    isMobile,
    startIntercom,
    stopIntercom,
    updateIntercom,
    userEmail,
  ]);

  useEffect(() => {
    const handleRouteChange = (path: string) => {
      if (environment === "production" || environment === "staging") {
        if (userEmail && workspaceId && workspaceName) {
          posthog?.capture("$pageview", {
            email: userEmail,
            workspaceId,
            workspaceName,
          });
        } else {
          posthog?.capture("$pageview");
        }
      }

      if (!isMobile && shouldDisplayIntercom(path, userEmail)) {
        updateIntercom({
          name: userName,
          email: userEmail,
          lastRequestAt: Math.floor(new Date().getTime() / 1000),
          hideDefaultLauncher: false,
        });
      } else {
        hideIntercom();
        updateIntercom({
          hideDefaultLauncher: true,
        });
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [
    environment,
    hideIntercom,
    isMobile,
    posthog,
    router.events,
    updateIntercom,
    userEmail,
    userName,
    workspaceId,
    workspaceName,
  ]);

  useInitNavigationStore();
  useLoadUserFromCurrentSession();
  useWebsocketListener();
  useListenForCreditsConfetti();
  useInitGmailApi();

  return (
    <Box
      display="flex"
      alignItems="stretch"
      className={clsx(
        MuseoSans.className,
        "full-min-height",
        isMac ? "container" : "custom-scroll container",
      )}
    >
      <Box width="100%">
        <Sentry.ErrorBoundary
          showDialog={false}
          fallback={SurfbreakPage}
          beforeCapture={(scope) => {
            scope.setTag("location", "AppErrorBoundary");
          }}
        >
          <>
            {children}
            <Transition />
          </>
        </Sentry.ErrorBoundary>
      </Box>
    </Box>
  );
};

export default AppWrapper;
