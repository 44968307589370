import { create } from "zustand";
import { useEffect } from "react";
import { useIsMobile } from "@/libs/hooksLib";

interface NavigationStore {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export const useNavigationStore = create<NavigationStore>((set) => ({
  open: true,
  setOpen: (open) => set({ open }),
}));

export const useInitNavigationStore = () => {
  const isMobile = useIsMobile();
  const setOpen = useNavigationStore((state) => state.setOpen);

  useEffect(() => {
    setOpen(!isMobile);
  }, [isMobile, setOpen]);
};
