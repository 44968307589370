import { Approval } from "@/approvals/model";
import { Ask } from "@/asks/model";
import { Discussion } from "@/discussions/model";
import { DocumentPermissionMap } from "@/libs/permissions";
import { Meeting } from "@/meetings/model";
import ShortUniqueId from "short-unique-id";
import axios from "axios";
import { convertBlobToBase64 } from "@/libs/images";

export const debounce = <Props extends any[], Return>(
  func: (...args: Props) => Promise<Return>,
  timeout = 500,
) => {
  let timer: NodeJS.Timeout;
  let promise: Promise<Return>;
  let resolve: (value: Return | PromiseLike<Return>) => void;
  let reject: (reason?: any) => void;
  return async (...args: Props) => {
    if (!promise) {
      promise = new Promise((res, rej) => {
        resolve = res;
        reject = rej;
      });
    }
    clearTimeout(timer);
    timer = setTimeout(async () => {
      timer = null;
      try {
        resolve(await func(...args));
      } catch (err) {
        reject(err);
      } finally {
        promise = null;
      }
    }, timeout);
    return promise;
  };
};

/**
 * Returns the base64 data of an image from the supplied url
 * @param url string
 */
export async function imageDataFromUrl(url: string) {
  try {
    const response = await axios.get(url, { responseType: "blob" });
    const blob = response.data;
    const base64 = (await convertBlobToBase64(blob)) as string;
    return { base64, blob };
  } catch (e) {
    return { base64: "", blob: {} };
  }
}

export function createId() {
  const uid = new ShortUniqueId();
  return uid.stamp(16);
}

const getIsMobileSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
};

export const linkOut = (url: string, download?: string) => {
  const a = document.createElement("a");
  document.body.appendChild(a);
  a.href = url;
  a.style.display = "none";
  //_blank does't work for mobile safari
  const isMobileSafari = getIsMobileSafari();
  if (!isMobileSafari) a.target = "_blank";
  if (download) a.download = download;
  a.click();
  document.body.removeChild(a);
};

export function openMultipleTabs(urlsArray: string[]) {
  urlsArray.forEach((url: string, key: number) => {
    if (key === 0) {
      window.open(url, `_blank_first_${key.toString()}`);
    } else {
      setTimeout(function () {
        window.open(url, `_blank_${key.toString()}`);
      }, 1500 * key);
    }
  });
}

interface Folder {
  id: string;
  allowedGroups: DocumentPermissionMap;
  allowedUsers: DocumentPermissionMap;
  allowedInvitedUsers: DocumentPermissionMap;
  author: string;
  isPublic?: boolean;
  published?: boolean;
}

export type DocumentType = Ask | Approval | Meeting | Discussion | Folder;
