export function getBaseURL() {
  if (typeof document !== "undefined") {
    const host = window.location.host;
    const hostname = window.location.hostname;
    const isLocal = hostname === "localhost" || hostname === "127.0.0.1";
    const scheme = isLocal ? "http" : "https";
    return `${scheme}://${host}`;
  } else {
    return "/";
  }
}

export function getEnvironmentString() {
  return process.env.NEXT_PUBLIC_ENVIRONMENT || "local";
}
