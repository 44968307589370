export const ImgToPngConvertor = (imageFileBlob: File, options?: any) => {
  options = options || {};

  const defaults = {};
  const settings = extend(defaults, options);

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  const imageEl = createImage();

  function createImage(options?: any) {
    options = options || {};
    const img = Image ? new Image() : document.createElement("img");

    img.style.width = options.width ? options.width + "px" : "auto";
    img.style.height = options.height ? options.height + "px" : "auto";

    return img;
  }

  function extend(target: any, source: any) {
    for (const propName in source) {
      if (Object.prototype.hasOwnProperty.call(source, propName)) {
        target[propName] = source[propName];
      }
    }

    return target;
  }

  async function process() {
    let resolve: any;
    const promise = new Promise<{ blob: Blob; base64: string }>(
      (r) => (resolve = r),
    );

    const imageUrl = window.URL.createObjectURL(imageFileBlob);

    imageEl.onload = (e: any) => {
      canvas.width = e.target.width;
      canvas.height = e.target.height;
      ctx.drawImage(e.target, 0, 0, e.target.width, e.target.height);
      canvas.toBlob(
        async (blob: any) => {
          const base64 = await convertBlobToBase64(blob);
          resolve({ blob, base64 });
        },
        "image/png",
        1,
      );
    };

    imageEl.src = imageUrl;
    if (settings.downloadEl) {
      settings.downloadEl.style.display = "none";
    }

    return promise;
  }

  return { process: process };
};

export const convertBlobToBase64 = async (blob: any): Promise<string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
  });
};
