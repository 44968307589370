import { useIsPublic, usePathname } from "@/libs/hooksLib";

import React from "react";
import { useRouter } from "next/router";

type ContextProps = {
  asPath?: string;
  location?: string;
  documentId?: string;
  params?: { [key: string]: string };
  isPreview?: boolean;
};

const LocationContext = React.createContext<ContextProps | null>(null);

interface Props {
  children: React.ReactNode;
  asPath?: string;
  isPreview?: boolean;
}

const DOCUMENT_LOCATIONS = ["ask", "approval", "discussion", "meeting"];

const LocationContextProvider: React.FunctionComponentFactory<Props> = ({
  children,
  asPath,
  isPreview = false,
}) => {
  const router = useRouter();
  const isPublic = useIsPublic();
  const paths = usePathname().split("/");
  const location = asPath
    ? asPath.split("?")[0].split("/")[isPublic ? 3 : 2]
    : paths[isPublic ? 3 : 2];
  const id = asPath?.split("?")[0].split("/")[3];
  const documentIdFromPath = router.query.documentId as string;
  const documentId = DOCUMENT_LOCATIONS.includes(location)
    ? id || documentIdFromPath
    : undefined;

  const params = asPath
    ? Object.fromEntries(
        asPath
          ?.split("?")[1]
          ?.split("&")
          .map((param) => param.split("=")) || [],
      )
    : Object.fromEntries(
        router.asPath
          ?.split("?")[1]
          ?.split("&")
          .map((param) => param.split("=")) || [],
      );

  return (
    <LocationContext.Provider
      value={{
        asPath,
        isPreview,
        location,
        documentId,
        params,
      }}
    >
      {children}
    </LocationContext.Provider>
  );
};

export function useLocationContext() {
  return React.useContext(LocationContext) || {};
}

export default LocationContextProvider;
