import {
  differenceInDays,
  differenceInMonths,
  differenceInWeeks,
  differenceInYears,
  format,
  formatDistanceToNowStrict,
  formatDuration,
  intervalToDuration,
  isValid,
  parse,
} from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

import { intlFormat } from "date-fns/fp";

// TODO: internationalization

export const formatDateTime = intlFormat(
  { locale: "en-US" },
  {
    day: "numeric",
    month: "long",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
  },
);

export const formatDate = intlFormat(
  { locale: "en-US" },
  {
    day: "numeric",
    month: "short",
    year: "numeric",
  },
);

export const formatTime = intlFormat(
  { locale: "en-US" },
  {
    hour: "numeric",
    minute: "2-digit",
  },
);

const longDateFormatOptions: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
  timeZoneName: "short",
};

export function epochMillisecondsToLongDateString(
  epochMilliseconds: number,
): string {
  if (!epochMilliseconds) return "";
  const date = new Date(epochMilliseconds);
  const formattedDate = date.toLocaleString("en-US", longDateFormatOptions);
  const [datePart, yearPart, timePart] = formattedDate.split(", ");
  return `${datePart.trim()}, ${yearPart} at ${timePart}`;
}

/**
 * Formats a duration in the format hours:minutes
 * @param duration number in milliseconds
 * @returns hh:mm or h:mm
 */
export const formatTimerDuration = (duration: number) => {
  const hours = Math.floor(duration / 3600000) + "";
  const minutes = Math.floor((duration % 3600000) / 60000) + "";
  if (minutes.length === 1) return `${hours}:0${minutes}`;
  return `${hours}:${minutes}`;
};

/** @returns nicely formatted time ago, or just date if its after a week */
export function ago(date: Date) {
  if (!date) {
    return "";
  }

  try {
    return differenceInWeeks(new Date(), date) >= 1
      ? formatDate(date)
      : formatDistanceToNowStrict(date, { addSuffix: true });
  } catch (e) {
    console.warn("Could not parse date", e);
    return "";
  }
}

export function timeAgo(date: Date) {
  if (!date) {
    return "";
  }

  try {
    const today = new Date();
    if (differenceInYears(today, date) >= 1) {
      return formatDistanceToNowStrict(date, {
        addSuffix: true,
        unit: "year",
      });
    } else if (differenceInMonths(today, date) >= 1) {
      return formatDistanceToNowStrict(date, {
        addSuffix: true,
        unit: "month",
      });
    } else if (differenceInWeeks(today, date) >= 1) {
      const weeks = differenceInWeeks(today, date);
      return `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (differenceInDays(today, date) >= 1) {
      return formatDistanceToNowStrict(date, {
        addSuffix: true,
        unit: "day",
      });
    } else {
      return "today";
    }
  } catch (e) {
    console.warn("Could not parse date", e);
    return "";
  }
}

export const compare = (a: Date, b: Date) => {
  if (!a) return -1;
  if (!b) return 1;
  return a?.getTime() - b?.getTime();
};

/**  Takes input date and time, returns a Date object with Date of Date and Time of Time. If time is invalid, returns current time on given date. */
export function setTime(date: number | Date, time: Date): Date {
  if (!isValid(time)) {
    return new Date(date);
  }
  return parse(format(time, "HH:mm"), "HH:mm", date);
}

const isLocalDST = isDST(new Date());

function isDST(date: Date) {
  // TODO:US Changes to Permanent DST on November,2023.
  // Actually not clear if this needs any changes.
  // if (getLocalTimeZoneLocale().split("/")[0] == "US" && isBefore(new Date(), whateverDayBillIsInEffect)) return true;
  const jan = new Date(date.getFullYear(), 0, 1).getTimezoneOffset();
  const jul = new Date(date.getFullYear(), 6, 1).getTimezoneOffset();
  return Math.max(jan, jul) !== date.getTimezoneOffset();
}

export function durationToString(interval: number | Date, abbreviate = true) {
  //Prevents surfbreak from supplying too long an interval
  if (!isValid(new Date(interval))) return "";

  let str = formatDuration(intervalToDuration({ start: 0, end: interval }), {
    format: ["hours", "minutes"],
  });
  if (!abbreviate) return str;
  str = str.replace("hours", "hr");
  str = str.replace("hour", "hr");
  str = str.replace("minutes", "min");
  str = str.replace("minute", "min");
  return str;
}

/** Gets input time zone locale's time zone name from keys in timeZonesObject */
export function getTimeZoneName(timeZone: string): string {
  for (const [key, val] of Object.entries(timeZonesObject)) {
    if (val === timeZone) {
      return key;
    }
  }

  const localUserOffset = format(new Date(), "xxx");
  for (const [key, val] of Object.entries(timeZonesObject)) {
    if (val.includes(localUserOffset)) {
      return key;
    }
  }
}

export function unAbbrTimeZone(abbrTimeZone: string): string {
  const unabbr = Object.keys(timeZonesObject).find(
    (key) => key.split(" ")[1] === abbrTimeZone,
  );
  return unabbr || "";
}

/** Gets local time zone name from keys in timeZonesObject */
export function getLocalTimeZoneName() {
  const userTimeZoneLocale = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return getTimeZoneName(userTimeZoneLocale);
}

/** Returns the time zone locale of current user. */
export function getLocalTimeZoneLocale() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/** Returns the abbreviation of the local time zone as per timeZonesObject's key's names. */
export function getLocalTimeZoneNameAbbr() {
  const userTimeZoneLocale = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const splitTimeZoneValue = getTimeZoneName(userTimeZoneLocale).split(" ");
  return splitTimeZoneValue[splitTimeZoneValue.length - 1];
}

/** Updates a given time, at input timezone locale into local time.  */
export function updateTimeFromSelectedToLocal(time: Date, tz?: string): Date {
  const localTimeZone = getLocalTimeZoneLocale();
  const selectedTimeZone = tz ? tz : localTimeZone;

  const selectedTimeZoneMoment = zonedTimeToUtc(time, selectedTimeZone);
  const localTimeDate = utcToZonedTime(selectedTimeZoneMoment, localTimeZone);
  return localTimeDate;
}

/** Determines if the input is an acceptable time zone.  */
export function isTimeZone(input: string): boolean {
  if (input in timeZonesObject) return true;
  try {
    return isValid(utcToZonedTime(new Date(), input));
  } catch (e) {
    return false;
  }
}

/** Determines if the input is an acceptable date.  */
export function isAcceptable(input: Date): boolean {
  return isValid(input) || input === null;
}

/** Converts a date to MM/dd/yyyy format string*/
export function dateToString(input: Date): string {
  if (!isValid(input)) return "";

  let stringedDate = "";
  const month = String(input.getMonth() + 1);
  const day = String(input.getDate());
  const year = String(input.getFullYear());

  if (month.length == 1) stringedDate += "0";
  stringedDate += month + "/";
  if (day.length == 1) stringedDate += "0";
  stringedDate += day + "/";
  stringedDate += year;
  return stringedDate;
}

function ordinal(number: number) {
  const signal = number < 20 ? number : Number(("" + number).slice(-1));
  switch (signal) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
}

export function formatOrdinalDate(
  input: Date,
  options?: {
    showYear?: boolean;
    showSuffix?: boolean;
  },
): string {
  if (!isValid(input)) return "-";
  const { showYear = true, showSuffix = true } = options || {};

  const monthsShort = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  let ordinalDate = `${monthsShort[input.getMonth()]} ${
    showSuffix ? ordinal(input.getDate()) : input.getDate()
  }`;
  if (showYear) {
    ordinalDate += `, ${input.getFullYear()}`;
  }
  return ordinalDate;
}

/** Converts a MM/dd/yyyy format string to a Date */
export function stringToDate(input: string): Date {
  const splitList = input.split("/");
  const month = Number(splitList[0]) - 1;
  const day = Number(splitList[1]);
  const year = Number(splitList[2]);
  return new Date(year, month, day);
}

/** Searches in timeZonesObject for the Key either directly before or after the given key name (in order seen by user).
 * Returns as a string. Abbreviated based on given parameters. Gets next/last based on given parameters. */
export function getKey(
  input: string,
  getNextNotLast: boolean,
  isAbbr?: boolean,
): string {
  const entries = Object.entries(timeZonesObject);
  const indexCurrent = entries.findIndex((item) => item[0].includes(input));
  const indexLength = entries.length - 1;
  const searchBoundary = getNextNotLast ? indexLength : 0;
  const offset = getNextNotLast ? 1 : -1;
  const index =
    indexCurrent === searchBoundary ? searchBoundary : indexCurrent + offset;
  return handleAbbreviation(Object.entries(timeZonesObject)[index][0], isAbbr);
}

/** Handles abbreviating (or not abbreviating) a timeZonesObject key string */
export function handleAbbreviation(
  stringToAbbr: string,
  isAbbr = false,
): string {
  return isAbbr ? stringToAbbr.split(" ")[1] : stringToAbbr;
}

/** Compares two dates to check if their hours and minutes are the same */
export function timeIsEqual(time1: Date, time2: Date): boolean {
  if (!time1 || !time2) return false;
  if (time1.getMinutes() !== time2.getMinutes()) return false;
  if (time1.getHours() !== time2.getHours()) return false;
  return true;
}

/** Object containing Key:(GMT+##:##) XXXX and Value: "?##:##" */
export const timeZonesObject: { [key: string]: string } = {
  "(UTC-12:00) BIT": isLocalDST ? "-11:00" : "-12:00",
  "(UTC-11:00) NUT": isLocalDST ? "-10:00" : "-11:00",
  "(UTC-10:00) CKT": isLocalDST ? "-09:00" : "-10:00",
  "(UTC-09:30) MIT": isLocalDST ? "-08:30" : "-09:30",
  "(UTC-09:00) AKT": isLocalDST ? "-08:00" : "-09:00",
  "(UTC-08:00) PST": isLocalDST ? "-07:00" : "-08:00",
  "(UTC-07:00) MST": isLocalDST ? "-06:00" : "-07:00",
  "(UTC-06:00) CST": isLocalDST ? "-05:00" : "-06:00",
  "(UTC-05:00) EST": isLocalDST ? "-04:00" : "-05:00",
  "(UTC-04:00) AMT": isLocalDST ? "-03:00" : "-04:00",
  "(UTC-03:30) NST": isLocalDST ? "-02:30" : "-03:30",
  "(UTC-03:00) ART": isLocalDST ? "-02:00" : "-03:00",
  "(UTC-02:00) FNT": isLocalDST ? "-01:00" : "-02:00",
  "(UTC-01:00) EGT": isLocalDST ? "+00:00" : "-01:00",
  "(UTC+00:00) GMT": isLocalDST ? "+01:00" : "+00:00",
  "(UTC+01:00) CET": isLocalDST ? "+02:00" : "+01:00",
  "(UTC+02:00) EET": isLocalDST ? "+03:00" : "+02:00",
  "(UTC+03:00) FET": isLocalDST ? "+04:00" : "+03:00",
  "(UTC+03:30) IRST": isLocalDST ? "+04:30" : "+03:30",
  "(UTC+04:00) AZT": isLocalDST ? "+05:00" : "+04:00",
  "(UTC+04:30) AFT": isLocalDST ? "+05:30" : "+04:30",
  "(UTC+05:00) PKT": isLocalDST ? "+06:00" : "+05:00",
  "(UTC+05:30) SLST": isLocalDST ? "+06:30" : "+05:30",
  "(UTC+05:45) NPT": isLocalDST ? "+06:45" : "+05:45",
  "(UTC+06:00) BIOT": isLocalDST ? "+07:00" : "+06:00",
  "(UTC+06:30) CCT": isLocalDST ? "+07:30" : "+06:30",
  "(UTC+07:00) WIT": isLocalDST ? "+08:00" : "+07:00",
  "(UTC+08:00) ACT": isLocalDST ? "+09:00" : "+08:00",
  "(UTC+08:45) CWST": isLocalDST ? "+09:45" : "+08:45",
  "(UTC+09:00) JST": isLocalDST ? "+10:00" : "+09:00",
  "(UTC+09:30) ACST": isLocalDST ? "+10:30" : "+09:30",
  "(UTC+10:00) AET": isLocalDST ? "+11:00" : "+10:00",
  "(UTC+10:30) LHST": isLocalDST ? "+11:30" : "+10:30",
  "(UTC+11:00) NCT": isLocalDST ? "+12:00" : "+11:00",
  "(UTC+12:00) FJT": isLocalDST ? "+13:00" : "+12:00",
  "(UTC+13:00) TOT": isLocalDST ? "+00:00" : "+00:00",
  "(UTC+13:45) CHAD": isLocalDST ? "+00:00" : "+00:00",
  "(UTC+14:00) LINT": isLocalDST ? "+00:00" : "+00:00",
};
