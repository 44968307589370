import { RestAPI as API } from "@aws-amplify/api-rest";
import { Auth } from "@aws-amplify/auth";
import axios from "axios";
import config from "@/config";
import { getEnvironmentString } from "@/libs/environment";

export async function getCurrentUser() {
  return await Auth.currentAuthenticatedUser();
}

export async function post(
  path: string,
  body: any = {},
  cancelSignal: AbortSignal = null,
) {
  try {
    const request = API.post("api", `${path}`, { body });
    // Cancel the request if abort signal given
    cancelSignal?.addEventListener("abort", () => {
      API.cancel(request);
    });
    return await request;
  } catch (e) {
    if (API.isCancel(e)) {
      return;
    }
    const environment = getEnvironmentString();
    if (environment !== "production") {
      console.error(`API Failure (POST): ${path}/${body?.api}`);
      console.error("Failed sending:", body);
      console.error("Message:", e?.response?.data?.msg);
    }
    throw e;
  }
}

export const patch = async (
  path: string,
  body: any = {},
  cancelSignal: AbortSignal = null,
) => {
  try {
    const request = API.patch("api", `${path}`, { body });
    // Cancel the request if abort signal given
    cancelSignal?.addEventListener("abort", () => {
      API.cancel(request);
    });
    return await request;
  } catch (e) {
    if (API.isCancel(e)) {
      return;
    }
    const environment = getEnvironmentString();
    if (environment !== "production") {
      console.error(`API Failure (PATCH): ${path}`);
      console.error("Failed sending:", body);
      console.error("Message:", e?.response?.data?.msg);
    }
    throw e;
  }
};

export async function get(
  path: string,
  queryParams: { [key: string]: string },
  cancelSignal: AbortSignal = null,
) {
  try {
    const request = API.get("api", `${path}`, queryParams);
    // Cancel the request if abort signal given
    cancelSignal?.addEventListener("abort", () => {
      API.cancel(request);
    });
    return await request;
  } catch (e) {
    if (API.isCancel(e)) {
      return;
    }
    const environment = getEnvironmentString();
    if (environment !== "production") {
      console.error(`API Failure (GET): ${path}.`);
      console.error("Message:", e?.response?.data?.msg);
    }
    throw e;
  }
}

export async function deleteAction(
  path: string,
  cancelSignal: AbortSignal = null,
) {
  try {
    const request = API.del("api", `${path}`, {});
    // Cancel the request if abort signal given
    cancelSignal?.addEventListener("abort", () => {
      API.cancel(request);
    });
    return await request;
  } catch (e) {
    if (API.isCancel(e)) {
      return;
    }
    const environment = getEnvironmentString();
    if (environment !== "production") {
      console.error(`API Failure (DELETE): ${path}.`);
      console.error("Message:", e?.response?.data?.msg);
    }
    throw e;
  }
}

export async function uploadFile(file: File, tenantId?: string) {
  const { url, key } = await post("/file", {
    api: "get_signed_url",
    name: file.name,
    type: file.type,
    tenantId,
  });
  await axios.put(url, file, {
    headers: { "Content-Type": file.type },
  });
  return { key, url: await getFileUrl(key, tenantId) };
}

export async function uploadImportFile(file: File, tenantId?: string) {
  const { url, key } = await post("/import", {
    api: "get_signed_url",
    name: file.name,
    type: file.type,
    tenantId,
  });
  await axios.put(url, file, {
    headers: { "Content-Type": file.type },
  });
  return { key, url: await getImportFileUrl(key, tenantId) };
}

export async function getFileUrl(key: string, tenantId?: string) {
  //Use public distribution link if tenant id isn't given
  if (!tenantId) return `https://${config.publicBlobDomain}/${key}`;

  const { url } = await post("/file", {
    api: "get_signed_url",
    key,
    type: "_", // type doesn't get used by api for downloads, but api expects it.
    tenantId,
  });
  return url;
}

export async function getImportFileUrl(key: string, tenantId: string) {
  const { url } = await post("/import", {
    api: "get_signed_url",
    key,
    type: "_", // type doesn't get used by api for downloads, but api expects it.
    tenantId,
  });
  return url;
}

interface PublicFileUrlParams {
  file: { key: string; tenantId?: string };
  tenantId: string;
  location: string;
  documentId: string;
}

export async function getPublicFileUrl({
  file,
  tenantId,
  location,
  documentId,
}: PublicFileUrlParams) {
  const remoteFile = await post("/public", {
    api: "get_signed_url",
    file,
    tenantId,
    location,
    documentId,
  });
  return remoteFile.url;
}
