import { FullEvent, PosthogEvent } from "@/lib/analytics/base_schema";

export const convertToPosthogEvent = (event: FullEvent): PosthogEvent => {
  const convertedEvent = { ...event };
  // Do not override the user's name.
  delete convertedEvent.name;

  return {
    ...convertedEvent,
  };
};
