import {
  analyticsDestinations,
  isExcludedEvent,
} from "@/lib/analytics/filters";
import { useMember, useTenantId, useTenantName } from "@/tenant/queries";

import { BaseEvent } from "@/lib/analytics/schema";
import { FullEvent } from "@/lib/analytics/base_schema";
import { convertToPosthogEvent } from "@/lib/analytics/conversions";
import { getEnvironmentString } from "@/libs/environment";
import { shallow } from "zustand/shallow";
import { useCallback } from "react";
import { usePostHog } from "posthog-js/react";
import { useUserStore } from "@/user/userStore";

export const useTrack = () => {
  const userId = useUserStore((state) => state.user?.id, shallow);
  const userName = useUserStore((state) => state.user?.name, shallow);
  const email = useUserStore((state) => state.user?.email, shallow);
  const member = useMember(userId);
  const workspaceId = useTenantId();
  const workspaceName = useTenantName();
  const bareTrack = useBareTrack();

  return useCallback(
    (event: BaseEvent) => {
      bareTrack(event, {
        user: {
          id: userId,
          name: userName,
          email,
        },
        role: member.role,
        groups: member.groups,
        workspaceId,
        workspaceName,
      }); //
    },
    [
      bareTrack,
      email,
      member.role,
      member.groups,
      userId,
      userName,
      workspaceId,
      workspaceName,
    ],
  );
};

export const useBareTrack = () => {
  const environment = getEnvironmentString();
  const posthog = usePostHog();

  return useCallback(
    (
      event: BaseEvent,
      {
        user = null,
        role = null,
        groups = null,
        workspaceId = null,
        workspaceName = null,
        attributes = {},
      },
    ) => {
      const sendToPlatform: { [key: string]: (ev: FullEvent) => void } = {
        posthog: (event: FullEvent) => {
          const eventName = event.name;
          const convertedMetric = convertToPosthogEvent(event);
          posthog?.capture(eventName, convertedMetric);
        },
      };
      // We only want to track events in production
      if (environment !== "production") return;

      const fullEvent: FullEvent = {
        ...event,
        user,
        role,
        groups,
        workspaceId,
        workspaceName,
        attributes,
        timestamp: Math.floor(Date.now() / 1000),
        environment,
      };

      // send to platforms
      for (const destination of analyticsDestinations) {
        // TODO: include?
        if (!isExcludedEvent(destination, event.name)) {
          sendToPlatform[destination](fullEvent);
        }
      }
    },
    [environment, posthog],
  );
};
