import { Type } from "@/asks/Types";

export interface Category {
  name: string;
  topics: string[];
}

// string values match cdk common.ts Role enum
export enum Role {
  Admin = "Admin",
  Collaborator = "Collaborator",
  Member = "Member",
}

export interface Invitee {
  email: string;
  role: Role;
  error?: string;
  processing?: boolean;
  key?: number;
}

export interface Member {
  id: string;
  name: string;
  company: string;
  email: string;
  role: Role;
  groups: string[];
  title: string;
  onboardingDone: boolean;
  lastActiveTime: Date;
  avatarUrl?: string;
  former?: boolean;
  credits?: { type: Type; title: string; askId: string; viewed: boolean }[];
}

export const unknownUser: Member = {
  id: "unknown",
  name: "Unknown User",
  company: "",
  email: "",
  role: null,
  groups: [],
  title: "unknown",
  onboardingDone: false,
  lastActiveTime: new Date(0),
  former: false,
};

export interface Invite {
  name: string;
  email: string;
  role: Role;
  groups: string[];
  inviteTime?: Date;
}

export interface TenantState {
  id: string;
  name: string;
  legalName: string;
  members: Member[];
  invites: Invite[];
  logo?: string;
}

export const initialState: TenantState = {
  id: "",
  name: "",
  legalName: "",
  members: [],
  invites: [],
  logo: "",
};

export interface UserInfo {
  id: string;
  name: string;
  isMember: boolean;
}
