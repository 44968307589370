import { Box, BoxProps, Typography } from "@mui/material";
import { Content, ListItemElement } from "@/custom.slate";
import { Invite, Member } from "@/tenant/model";

import { Meeting } from "@/meetings/model";
import React from "react";
import { createId } from "@/libs/misc";
import { getEnvironmentString } from "@/libs/environment";
import { getTimeZoneName } from "@/libs/datetime";
import { useUrl } from "@/libs/hooksLib";

// This is copied from stackoverflow (forgot which one it is). If it stops working, feel free to replace anyone that works.
const EmailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function isValidEmail(email: string) {
  if (!email) return false;
  return EmailRegEx.test(email.trim());
}

const SpecialRegEx = /[~`!@#$%^&*()_+=\-[\]\\';.,/{}|\\":<>?]/;

export function hasSpecialChar(text: string) {
  return SpecialRegEx.test(text);
}

export function isValidUrl(str: string) {
  if (!str) return false;

  //Returns true if the string is localhost on dev side
  const environment = getEnvironmentString();
  if (environment !== "production" && environment !== "staging") {
    if (str.includes("localhost")) return true;
    if (str.includes("127.0.0.1")) return true;
  }
  //Otherwise, returns a more strict url match
  const matchPattern =
    /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9!@#$%^&*()-=_+[\]{}|;':",.<>/?]*)$/gm;
  return matchPattern.test(str);
}

export function isValidBase64(str: string) {
  if (!str) return false;

  const matchPattern = /^data:[a-z]+\/[a-z]+;[-A-Za-z0-9+=]{1,50}|=[^=]|={3,}$/;
  return matchPattern.test(str);
}

/**
 * Given MIME type, return main type, subtype, and params.
 *
 * See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types
 */
export function typeFromMIME(text: string): [string, string, string] {
  const [type, rest] = text.split("/");
  const [subtype, params] = rest.split(";");
  return [type, subtype, params];
}

export function useQuerystring(name: string) {
  const url = useUrl();
  name = name.replace(/[[]]/g, "\\$&");

  const regex = new RegExp("[?&]" + name + "(=([^#]*)|&|#|$)", "i");
  const results = regex.exec(url);

  if (!results) {
    return null;
  }
  if (!results[2]) {
    return "";
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

/** Remove any leading http:// or https:// from link */
export function removeHTTP(url: string) {
  return url.replace(/^https?:\/\//, "");
}

export function handleTitle(
  savedTitle: string,
  editing: boolean,
  unsavedTitle: string,
  published: boolean,
  content: any,
  location: string,
) {
  return savedTitle && !editing
    ? savedTitle
    : unsavedTitle
      ? unsavedTitle
      : published && !content
        ? "Loading..."
        : "Untitled " + location;
}

const quotes = [
  //If added more quotes, copy the actual emoji rather than unicode
  //Unicode may not be displayed properly
  "⭐ You are building something epic",
  "🔥 Believe in yourself and anything is possible",
  "💭 Dreams don't work unless you do",
  "🚀 Build the tomorrow you want today",
  "🙌 Big journeys begin with small steps",
  "☀️ It's a beautiful day",
  "💪 If you can dream it, you can do it",
  "❤️ Trust yourself and make it happen",
  "⏩ You are unstoppable",
  "🎁 You are a gift to the world",
];

export const getRandomMotivationQuote = (): string => {
  return quotes[Math.floor(Math.random() * quotes.length)];
};

/**
 * Takes a string and makes each character the same width, primarily intended for dynamic counter text
 * @param str String to tabulate
 * @param styles Any mui box properties to style the characters
 * @returns ReactNode
 */
export const tabulateChars = (str: string, styles?: BoxProps) => {
  const chars = str.split("");
  return (
    <Box component="span">
      {chars.map((char) => (
        <Typography key={createId()} component="span" display="inline-block">
          <Box
            component="span"
            display="flex"
            justifyContent="center"
            width={[" ", ":", "/"].includes(char) ? "6px" : "10px"}
            {...styles}
          >
            {char}
          </Box>
        </Typography>
      ))}
    </Box>
  );
};

export const abbreviateNumber = (value: number) => {
  let newValue = value + "";
  if (value >= 1000) {
    const suffixes = ["", "K", "M", "B", "T"];
    const suffixNum = Math.floor(("" + value).length / 3);
    let shortValue;
    for (let precision = 2; precision >= 1; precision--) {
      shortValue = parseFloat(
        (suffixNum != 0
          ? value / Math.pow(1000, suffixNum)
          : value
        ).toPrecision(precision),
      );
      const dotLessShortValue = (shortValue + "").replace(
        /[^a-zA-Z 0-9]+/g,
        "",
      );
      if (dotLessShortValue.length <= 2) break;
    }
    let str = shortValue + "";
    if (shortValue % 1 != 0) str = shortValue.toFixed(1);
    newValue = str + suffixes[suffixNum];
  }
  return newValue;
};

const createListItemArray = (strings: string[], color: string) => {
  if (!strings?.length) return [];
  return strings.map((str) => ({
    type: "list_item",
    children: [{ text: str, color }],
  })) as ListItemElement[];
};

export const generateMeetingMinuteContent = (
  tenantName: string,
  meetingTemplate: Meeting,
  meetingAuthor: string,
  presentDirectors: string[],
  absentDirectors: string[],
  undecidedDirectors: string[],
  color = "blue",
) => {
  const meetingDate =
    meetingTemplate?.startTime.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    }) || "Date";
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const TzAbbr = () => getTimeZoneName(timeZone).split(" ")[1];
  const meetingTime = meetingTemplate
    ? meetingTemplate.startTime
        .toLocaleDateString("en-US", {
          minute: "2-digit",
          hour: "2-digit",
        })
        .split(", ")[1] +
      " " +
      TzAbbr()
    : "Time";
  const meetingAgenda =
    meetingTemplate?.outline
      .flatMap(({ title }, index) => `${index + 1}. ${title}`)
      .join(" • ") || "Agenda";
  const presentDirectorsList = createListItemArray(presentDirectors, color);
  const undecidedDirectorsList = createListItemArray(undecidedDirectors, color);
  const absentDirectorsList = createListItemArray(absentDirectors, color);
  return [
    {
      type: "paragraph",
      children: [
        {
          text: "ACTION BY UNANIMOUS WRITTEN CONSENT OF THE BOARD OF DIRECTORS OF ",
          bold: true,
        },
        {
          text: tenantName.toLocaleUpperCase() || "Company name",
          bold: true,
          color,
        },
      ],
    },
    {
      type: "paragraph",
      children: [
        {
          text: "The undersigned, constituting all of the members of the Board of Directors of (the “Board”) of ",
        },
        {
          text: (tenantName || "Company name") + ", a Delaware corporation ",
          color,
        },
        {
          text: "(the “Company”), pursuant to ",
        },
        {
          text: "Section 141(f) of the Delaware General Corporation Law and ",
          color,
        },
        {
          text: "the Bylaws of the Company, do hereby adopt the following resolutions by unanimous written consent effective as of the latest date set forth on the signature page hereto:\n\n",
        },
      ],
    },
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
    {
      type: "paragraph",
      children: [{ text: "Approval of Board Minutes", bold: true }],
    },
    {
      type: "paragraph",
      children: [
        { text: "RESOLVED", bold: true },
        { text: ", that the minutes of the meetings of the Board held on " },
        { text: meetingDate, color },
        {
          text: " be, and they hereby are, approved in the form attached hereto as Exhibit A.",
        },
      ],
    },
    {
      type: "paragraph",
      children: [
        { text: "IN WITNESS WHEREOF", bold: true },
        {
          text: ", the undersigned have executed this Action by Unanimous Written Consent as of the date set forth above.",
        },
      ],
    },
    {
      type: "paragraph",
      children: [{ text: "" }],
    },
    {
      type: "paragraph",
      children: [{ text: "Exhibit A", bold: true, underline: true }],
    },
    {
      type: "paragraph",
      children: [
        {
          text: "MINUTES OF A REGULAR MEETING OF THE BOARD OF DIRECTORS OF ",
          bold: true,
        },
        {
          text: (tenantName.toLocaleUpperCase() || "Company name") + ".",
          color,
          bold: true,
        },
      ],
    },
    {
      type: "paragraph",
      children: [
        {
          text: "DATE: ",
          bold: true,
        },
        { text: meetingDate, color },
      ],
    },
    {
      type: "paragraph",
      children: [
        {
          text: "TIME: ",
          bold: true,
        },
        { text: meetingTime, color },
      ],
    },
    {
      type: "paragraph",
      children: [
        {
          text: "PLACE: ",
          bold: true,
        },
        { text: meetingTemplate?.location || "Address / Online", color },
      ],
    },
    {
      type: "paragraph",
      children: [
        {
          text: "DIRECTORS PRESENT:",
          bold: true,
        },
      ],
    },
    {
      type: "unordered_list",
      children: [
        ...presentDirectorsList,
        ...undecidedDirectorsList,
        ...(!presentDirectorsList.length && !undecidedDirectorsList.length
          ? [{ type: "list_item", children: [{ text: "Name", color }] }]
          : []),
      ],
    },
    {
      type: "paragraph",
      children: [{ text: "DIRECTORS ABSENT:", bold: true }],
    },
    {
      type: "unordered_list",
      children: [
        ...absentDirectorsList,
        ...(!absentDirectorsList.length
          ? [{ type: "list_item", children: [{ text: "Name", color }] }]
          : []),
      ],
    },
    {
      type: "paragraph",
      children: [{ text: "OTHERS PRESENT:", bold: true }],
    },
    {
      type: "unordered_list",
      children: [
        {
          type: "list_item",
          children: [{ text: "Name", color }],
        },
      ],
    },
    { type: "paragraph", children: [{ text: "" }] },
    { type: "paragraph", children: [{ text: "Call to Order.", bold: true }] },
    {
      type: "paragraph",
      children: [
        { text: meetingAuthor, color },
        {
          text: " called to order the meeting of the Board of Directors (the “Board”) of ",
        },
        {
          text: tenantName || "Company name",
          color,
          bold: true,
        },
        {
          text: " (the “Company”), at the above place and time.  ",
        },
        {
          text: meetingAuthor,
          color,
        },
        {
          text: ", acting as the Chairman of the meeting, declared that a quorum of directors was present and that the meeting, having been duly convened, was ready to proceed with its business. ",
        },
        {
          text: meetingAuthor,
          color,
        },
        {
          text: " acted as Secretary of the meeting. ",
        },
        {
          text: meetingAuthor,
          color,
        },
        {
          text: " reviewed the agenda for the meeting:",
        },
      ],
    },
    {
      type: "paragraph",
      children: [{ text: meetingAgenda, color }],
    },
    {
      type: "paragraph",
      children: [
        { text: meetingAuthor, color },
        {
          text: " presented each of the foregoing topics and discussion ensued.",
        },
      ],
    },
    {
      type: "paragraph",
      children: [{ text: "Adjournment.", bold: true }],
    },
    {
      type: "paragraph",
      children: [
        {
          text: "There being no further business to come before the meeting, upon motion duly made, seconded and unanimously carried, the meeting was adjourned.",
        },
      ],
    },
    { type: "paragraph", children: [{ text: "" }] },
    {
      type: "paragraph",
      children: [{ text: meetingAuthor, color }],
    },
    {
      type: "paragraph",
      children: [{ text: "Secretary to the Meeting" }],
    },
  ] as Content;
};

export const formatNumPeople = (num: number) => {
  if (num === 1) return `${num} person`;
  return `${num} people`;
};

export const getIdFromUser = (u: Member | Invite) =>
  "id" in u ? u.id : u.email;
