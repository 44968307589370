const config = {
  userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
  identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
  userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID,
  region: process.env.NEXT_PUBLIC_REGION,
  apiUrl: process.env.NEXT_PUBLIC_API_URL,
  websocketUrl: process.env.NEXT_PUBLIC_WEBSOCKET_URL,
  publicBlobDomain: process.env.NEXT_PUBLIC_PUBLIC_BLOB_DOMAIN,
  redirectSignIn: process.env.NEXT_PUBLIC_REDIRECT_SIGN_IN,
  redirectSignOut: process.env.NEXT_PUBLIC_REDIRECT_SIGN_OUT,
  userPoolDomain: process.env.NEXT_PUBLIC_USER_POOL_DOMAIN,
  slackClientId: process.env.NEXT_PUBLIC_SLACK_CLIENT_ID,
};

export default config;
