import { alpha, styled } from "@mui/system";

// teal from marketing UI kit
const teal = "#5FE1D9";

export const GradientBackground = styled("div")(({ theme }) => ({
  height: "100%",
  width: "100%",
  overflow: "auto",
  background: `linear-gradient(180deg, ${alpha(teal, 0)} 66.67%, ${alpha(
    teal,
    0.2,
  )} 100%), ${theme.palette.offwhite.main}`,
  fallbacks: {
    background: theme.palette.offwhite.main,
  },
}));
