import { create } from "zustand";

interface Props {
  message: string;
  title: string;
  actionText: string;
  cancelText: string;
  open: boolean;
}

interface Store {
  props: Props;
  handleClose: (yes: boolean) => void;
  open: (
    message: string,
    title: string,
    actionText?: string,
    cancelText?: string,
  ) => Promise<boolean>;
}

let confirmResolve: (yes: boolean) => void = undefined;

export const useConfirmDialogStore = create<Store>((set) => ({
  props: {
    message: "",
    title: "",
    actionText: "",
    cancelText: "",
    open: false,
  },
  handleClose: (yes) => {
    set({
      props: {
        message: "",
        title: "",
        actionText: "",
        cancelText: "",
        open: false,
      },
    });
    confirmResolve(yes);
  },
  open: (message, title, actionText = "Confirm", cancelText = "Cancel") => {
    set({ props: { open: true, message, title, actionText, cancelText } });
    return new Promise<boolean>((resolve) => (confirmResolve = resolve));
  },
}));

export const useConfirm = () => {
  return useConfirmDialogStore().open;
};

export const useHandleConfirmClose = () => {
  return useConfirmDialogStore().handleClose;
};
