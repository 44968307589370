import { AskType } from "@/asks/model";
import { create } from "zustand";

interface Props {
  open: boolean;
  credits: {
    type: AskType;
    title: string;
    tenantId: string;
    askId: string;
    viewed: boolean;
  }[];
}

interface Store {
  props: Props;
  handleClose: () => void;
  open: (confettiDialog: Props) => void;
}

export const useConfettiDialogStore = create<Store>((set) => ({
  props: { open: false, credits: [] },
  open: (props) => set({ props }),
  handleClose: () => set({ props: { open: false, credits: [] } }),
}));

export const useOpenConfettiDialog = () => {
  return useConfettiDialogStore().open;
};

export const useHandleConfettiClose = () => {
  return useConfettiDialogStore().handleClose;
};
