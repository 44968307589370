import { post, uploadFile } from "@/libs/awsLib";
import {
  useAcceptUserInvite,
  useIsAuthenticated,
  useSetCognitoUser,
  useSetCredentialsUsed,
  useSetEmptyUser,
  useSetLoading,
  useSetOnboardingDone,
  useSetUser,
  useSetUserPrefs,
  useSetUserProfile,
  useSetUserSignature,
  useUserStore,
} from "@/user/userStore";
import { useEffect, useRef } from "react";
import { useSetToast, useSetTransitioning } from "@/store/transitionStore";

import { Auth } from "@aws-amplify/auth";
import { ProfileProps } from "@/user/model";
import { SetEmailNotificationSettingsPayload } from "@/unsubscribe/models";
import { getEnvironmentString } from "@/libs/environment";
import { shallow } from "zustand/shallow";
import { useBareTrack } from "@/lib/analytics/track";
import { useCookies } from "react-cookie";
import { useIdentify } from "@/lib/analytics/identify";
import { useOpenConfettiDialog } from "@/dialogs/confettiDialog/store";
import { usePostHog } from "posthog-js/react";
import { useRouter } from "next/router";

export const useMarkAsOnboarded = () => {
  const bareTrack = useBareTrack();
  const setOnboardingDone = useSetOnboardingDone();
  const userEmail = useUserStore((state) => state.user.email, shallow);
  return async () => {
    await post("/user", { api: "complete_onboarding" });
    bareTrack({ name: "user_onboarding_completed" }, { user: userEmail });
    setOnboardingDone(true);
  };
};

export const useSetProfile = () => {
  const setProfile = useSetUserProfile();
  return async (props: ProfileProps) => {
    setProfile(props);
    await post("/user", { api: "set_profile", ...props });
  };
};

export const useSetSignature = () => {
  const setSignature = useSetUserSignature();
  return async (signature: string) => {
    await post("/user", {
      api: "set_signature",
      signature,
    });
    setSignature(signature);
  };
};

export const useAcceptInvite = () => {
  const acceptInvite = useAcceptUserInvite();
  const bareTrack = useBareTrack();
  const userEmail = useUserStore((state) => state.user.email, shallow);
  const userId = useUserStore((state) => state.user.id, shallow);
  return (tenantId: string) => {
    bareTrack(
      {
        name: "workspace_joined",
        attributes: { email: userEmail, id: userId, tenantId },
      },
      {
        user: userId,
      },
    );
    acceptInvite(tenantId);
  };
};

export const uploadAvatar = async (file: File) => {
  const { url } = await uploadFile(file);
  await post("/user", {
    api: "set_profile",
    avatarUrl: url,
  });
};

export const setLastTenant = async (tenantId: string) => {
  await post("/user", { api: "set_last_tenant", tenantId });
};

export const setUsername = async (name: string) => {
  await post("/user", { api: "set_username", name });
};

export const useFetchAndLoadUser = () => {
  const setUser = useSetUser();
  return async () => {
    const user = await post("/user", { api: "load" });
    setUser(user);
    return user;
  };
};

export const useLoadAuthenticatedUser = () => {
  const setLoading = useSetLoading();
  const fetchAndLoadUser = useFetchAndLoadUser();
  const setToast = useSetToast();
  const identify = useIdentify();
  const setEmptyUser = useSetEmptyUser();
  const [, setCookie] = useCookies(["surfboardAuthState"]);
  const environment = getEnvironmentString();

  return async () => {
    setLoading(true);
    try {
      const loadedUser = await fetchAndLoadUser();
      setCookie(
        "surfboardAuthState",
        { lastLoginTime: Date.now() },
        {
          path: "/",
          maxAge: 2592000, // Expires in 30 days
          domain:
            environment === "production"
              ? "surfboard.team"
              : environment === "staging"
                ? "surfboard.live"
                : null,
        },
      );
      identify(loadedUser);
    } catch (e) {
      setToast(e.response?.data?.msg);
      setEmptyUser();
    }
    setLoading(false);
  };
};

export const useConfirmResetPassword = () => {
  const loadUser = useLoadAuthenticatedUser();

  return async (email: string, password: string, code: string) => {
    try {
      await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);
      await Auth.signIn(email.toLowerCase(), password, {
        credentialsUsed: "true",
      });
      await loadUser();
      return "";
    } catch (e) {
      return e.message;
    }
  };
};

export const useResetPassword = () => {
  const router = useRouter();
  return async (email: string) => {
    try {
      await Auth.forgotPassword(email.toLowerCase());
      return "";
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        const { isReset } = await post("/public", {
          api: "check_user_status",
          email,
        });
        if (isReset) {
          await post("/public", {
            api: "request_invite_link",
            email,
          });
          router.push({ pathname: "/pending", query: { email } });
          return "REDIRECT";
        } else return error.message;
      } else {
        switch (error.code) {
          case "UserNotFoundException":
            return "The email you entered isn’t connected to an account.";
          default:
            return error.message;
        }
      }
    }
  };
};

export const resendConfirmationCode = async (email: string) => {
  try {
    await Auth.resendSignUp(email.toLowerCase());
    return "";
  } catch (error) {
    return error.message;
  }
};

export const useSignOut = () => {
  const router = useRouter();
  const bareTrack = useBareTrack();
  const setEmptyUser = useSetEmptyUser();
  const userEmail = useUserStore((state) => state.user.email, shallow);
  const posthog = usePostHog();
  const environment = getEnvironmentString();
  const setTransition = useSetTransitioning();
  const [, , removeCookie] = useCookies(["surfboardAuthState"]);

  return async ({
    withRedirect,
    useTransitioning,
  }: {
    useTransitioning?: boolean;
    withRedirect?: boolean;
  }) => {
    removeCookie("surfboardAuthState", {
      path: "/",
      domain:
        environment === "production"
          ? "surfboard.team"
          : environment === "staging"
            ? "surfboard.live"
            : null,
    });

    if (useTransitioning) {
      setTransition(true);
    }

    try {
      await Auth.signOut();
      bareTrack({ name: "signed_out" }, { user: userEmail });
      setEmptyUser();

      if (environment === "production" || environment === "staging") {
        posthog.reset();
      }

      // Tell the browser extension to log out
      if (!!window) {
        try {
          //@ts-ignore
          chrome.runtime.sendMessage(
            process.env.NEXT_PUBLIC_CHROME_EXTENSION_ID,
            { event: "signed_out" },
            function (response: any) {
              console.info("Message response: ", response);
            },
          );
        } catch {}
      }

      if (withRedirect) {
        await router.push(
          environment === "production"
            ? "https://www.surfboard.team"
            : environment === "staging"
              ? "https://www.surfboard.live"
              : "/",
        );
      }
    } finally {
      if (useTransitioning) {
        setTransition(false);
      }
    }
  };
};

export const useConfirmSignup = () => {
  const bareTrack = useBareTrack();
  const loadUser = useLoadAuthenticatedUser();

  return async (email: string, password: string, code: string) => {
    try {
      await Auth.confirmSignUp(email.toLowerCase(), code);
      await Auth.signIn(email.toLowerCase(), password, {
        credentialsUsed: "true",
      });
      bareTrack({ name: "sign_up_confirmed" }, { user: email });
      await loadUser();
      return "";
    } catch (e) {
      return e.message;
    }
  };
};

export const useSignUp = () => {
  const bareTrack = useBareTrack();
  const router = useRouter();

  return async (
    name: string,
    email: string,
    password: string,
    recaptchaToken: string,
  ) => {
    email = email.toLowerCase();
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: { name },
        validationData: {
          recaptchaToken: recaptchaToken,
        },
      });

      bareTrack(
        { name: "sign_up_started" },
        { user: email, attributes: { invited: false } },
      );

      return "";
    } catch (e) {
      if (e.code === "UsernameExistsException") {
        const { isReset } = await post("/public", {
          api: "check_user_status",
          email,
        });
        if (isReset) {
          try {
            await post("/public", {
              api: "request_invite_link",
              email,
            });
            router.push({ pathname: "/pending", query: { email } });
            return "REDIRECT";
          } catch (e) {
            return e.message;
          }
        } else return e.message;
      } else return e.message;
    }
  };
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string,
) => {
  try {
    const currentUser = await Auth.currentAuthenticatedUser();
    await Auth.changePassword(currentUser, oldPassword, newPassword);
    return "";
  } catch (e) {
    return e.message;
  }
};

export const useCompleteNewPassword = () => {
  const bareTrack = useBareTrack();

  const loadUser = useLoadAuthenticatedUser();
  const cognitoUser = useUserStore((state) => state.cognitoUser);
  return async (name: string, password: string) => {
    try {
      await Auth.completeNewPassword(cognitoUser, password, { name });
      await Auth.signIn(cognitoUser.email, password, {
        credentialsUsed: "true",
      });
      await loadUser();

      bareTrack({ name: "sign_up_confirmed" }, { user: cognitoUser.email });

      return "";
    } catch (e) {
      return e.message;
    }
  };
};

export const useSignIn = () => {
  const loadUser = useLoadAuthenticatedUser();
  const bareTrack = useBareTrack();
  const router = useRouter();
  const setCognitoUser = useSetCognitoUser();
  return async (email: string, password: string) => {
    email = email.toLowerCase();
    try {
      const user = await Auth.signIn(email, password, {
        credentialsUsed: "true",
      });
      if (user.signInUserSession) {
        bareTrack(
          { name: "signed_in" },
          {
            user: email,
            role: user.role,
            groups: user.groups,
          },
        );
        await loadUser();
        return "";
      } else if (user.challengeName) {
        user.email = email;
        setCognitoUser(user);
        return user.challengeName;
      }
    } catch (error) {
      if (error.code === "NotAuthorizedException") {
        const { isReset } = await post("/public", {
          api: "check_user_status",
          email,
        });
        if (isReset) {
          await post("/public", {
            api: "request_invite_link",
            email,
          });
          router.push({ pathname: "/pending", query: { email } });
          return "REDIRECT";
        } else return error.message;
      } else return error.message;
    }
  };
};

export const useJoinSurfboard = () => {
  const signIn = useSignIn();
  return async (email: string, temporaryPassword: string) => {
    true;
    email = email.toLowerCase();
    const msg = await signIn(email, temporaryPassword);
    switch (msg) {
      case "Incorrect username or password.":
        return "USER_EXISTS";
      case "Temporary password has expired and must be reset by an administrator.":
        return "INVITE_EXPIRED";
      default:
        return msg;
    }
  };
};

interface SecureLinkSignInResponse {
  error?: any;
}

export const useSecureLinkSignIn = () => {
  const bareTrack = useBareTrack();
  const loadUser = useLoadAuthenticatedUser();
  const completeSignUpUserName = useSendCustomAuthUsernameChallenge();

  return async (
    email: string,
    code: string,
  ): Promise<SecureLinkSignInResponse> => {
    try {
      let cognitoUser;
      cognitoUser = await Auth.signIn(email);
      cognitoUser = await Auth.sendCustomChallengeAnswer(
        cognitoUser,
        code as string,
      );

      const requiresName = cognitoUser.challengeParam?.requiresName;
      if (requiresName) {
        const defaultUsername = email.split("@")[0];
        await completeSignUpUserName({
          cognitoUser,
          name: defaultUsername,
          shouldCompleteUsernameAfterSignin: true,
        });
      }

      bareTrack(
        { name: "signed_in" },
        {
          user: cognitoUser.email,
          role: cognitoUser.role,
          groups: cognitoUser.groups,
        },
      );
      await loadUser();

      return {};
    } catch (error: any) {
      return { error };
    }
  };
};

export const useSendCustomAuthUsernameChallenge = () => {
  const loadUser = useLoadAuthenticatedUser();
  const bareTrack = useBareTrack();
  return async ({
    cognitoUser,
    name,
    shouldCompleteUsernameAfterSignin,
  }: {
    cognitoUser: any;
    name: string;
    shouldCompleteUsernameAfterSignin: boolean;
  }) => {
    try {
      const result = await Auth.sendCustomChallengeAnswer(cognitoUser, name, {
        shouldCompleteUsername: `${shouldCompleteUsernameAfterSignin}`,
      });
      bareTrack({ name: "sign_up_confirmed" }, { user: result.email });
      await loadUser();
      return "";
    } catch (e) {
      return e.message;
    }
  };
};

export const useLoadUserFromCurrentSession = () => {
  const router = useRouter();
  const loadUser = useLoadAuthenticatedUser();
  const isAuthenticated = useIsAuthenticated();
  const authInProgress = useRef(false);
  const setLoading = useSetLoading();

  useEffect(() => {
    if (isAuthenticated()) {
      setLoading(false);
      return;
    }
    if (
      isAuthenticated() ||
      authInProgress.current ||
      router.pathname.includes("chrome_logout")
    ) {
      return;
    }
    authInProgress.current = true;
    (async () => {
      try {
        await Auth.currentSession();
        await Auth.currentAuthenticatedUser();
        await loadUser();
      } catch (e) {
        if (e !== "No current user") {
          console.error("Issue in UserStore:", e);
        } else {
          console.info("Issue in UserStore: no credentials");
        }
      }
      authInProgress.current = false;
      setLoading(false);
    })();
  }, [isAuthenticated, loadUser, router.pathname, setLoading]);
};

export const useListenForCreditsConfetti = () => {
  const userCredits = useUserStore((state) => state.user?.credits, shallow);
  const openConfettiDialog = useOpenConfettiDialog();
  const isAuthenticated = useIsAuthenticated();
  const newCredits = userCredits?.filter((c) => !c.viewed);
  const confettiShowed = useRef(false);
  useEffect(() => {
    if (isAuthenticated() && !confettiShowed.current) {
      if (newCredits?.length) {
        confettiShowed.current = true;
        openConfettiDialog({ open: true, credits: newCredits });
      }
    }
  }, [isAuthenticated, newCredits, openConfettiDialog]);
};

export const useSetPreferences = () => {
  const setUserPrefs = useSetUserPrefs();
  return (props: SetEmailNotificationSettingsPayload) => {
    setUserPrefs(props.notificationSettings);
    return post("/user", { api: "set_notification_settings", ...props });
  };
};
export const useSetFirstTimePasswordMutation = () => {
  const setCredentialsUsed = useSetCredentialsUsed();
  return async (password: string) => {
    post("/user", {
      api: "set_first_time_password",
      password,
    });
    setCredentialsUsed(true);
  };
};

export const useSetAsksTableViewMutation = () => {
  const setAsksTableViewInStore = useUserStore(
    (state) => state.setAsksTableView,
  );
  return async (value: boolean) => {
    setAsksTableViewInStore(value);
    try {
      await post("/user", {
        api: "set_asks_table_view",
        asksTableView: value,
      });
    } catch (e) {
      console.error(e);
      setAsksTableViewInStore(!value);
    }
  };
};

export const setUnreadOnlyPreference = async (toggle: boolean) => {
  await post("/user", { api: "set_unread_only_preference", toggle });
};
