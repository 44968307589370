import { getEnvironmentString } from "@/libs/environment";
import { isExcludedAnalyticsUser } from "@surfboard/lib";
import { usePostHog } from "posthog-js/react";
import { useRouter } from "next/router";

export const shouldDisplayIntercom = (path: string, email: string): boolean => {
  if (!email) {
    return false;
  }

  const allowedEndingPaths = [
    "approval",
    "ask",
    "dashboard",
    "discussion",
    "management",
    "meeting",
    "notifications",
    "onboarding",
    "profile",
    "quickstart",
    "sentiments",
    "workspaces",
  ];

  const isAllowedRoute = !!allowedEndingPaths.includes(
    path.split("/").splice(-1)[0],
  );

  const isAllowedUser = !isExcludedAnalyticsUser(email, getEnvironmentString());

  return isAllowedRoute && isAllowedUser;
};

export const useIdentify = () => {
  const posthog = usePostHog();
  const router = useRouter();

  return (user: { name?: string; email?: string }) => {
    const userEmail = user?.email;
    const userName = user?.name;

    if (
      userEmail &&
      userName &&
      !isExcludedAnalyticsUser(userEmail, getEnvironmentString())
    ) {
      if (window.Intercom) {
        window.Intercom("update", {
          hide_default_launcher:
            !router.isReady || !shouldDisplayIntercom(router.asPath, userEmail),
          name: userName,
          email: userEmail,
          pending: false,
        });
      }

      posthog?.identify(userName, {
        name: userName,
        email: userEmail,
        pending: false,
      });
    }
  };
};
