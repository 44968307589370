import { Workbox } from "workbox-window";
import { useConfirm } from "@/dialogs/confirmDialog/store";
import { useCookies } from "react-cookie";
import { useEffect } from "react";
import { useIsEditing } from "@/libs/queryParamsUtils";

const getGitCommitSha = () => {
  return process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "development";
};

const useHandleMessage = () => {
  const confirm = useConfirm();
  const sha = getGitCommitSha();
  const isEditing = useIsEditing();
  const [cookies, setCookies] = useCookies(["remind_me_later"]);
  const remindMeLater = cookies["remind_me_later"];

  return async (event: MessageEvent<any>) => {
    if (event.data.type === "REQUEST_VERSION") {
      event.source.postMessage({ type: "VERSION", version: sha });
    } else if (event.data.type === "UPDATE_AVAILABLE") {
      if (isEditing) return;

      const remindMeLaterTime = new Date(remindMeLater || 0);
      const anHourAgo = new Date(new Date().getTime() - 3600000);
      if (remindMeLater && remindMeLaterTime > anHourAgo) return;

      console.info("Update available");
      console.info("Cached version sha:", sha);
      console.info("Current version sha:", event.data.sha);

      const yes = await confirm(
        "A new version is available. Reload to update?",
        "New version available",
        "Reload",
        "Remind me later",
      );
      if (yes) event.source.postMessage({ type: "CLEAR_CACHE" });
      else setCookies("remind_me_later", new Date().toISOString());
    } else if (event.data.type === "RELOAD_PAGE") {
      const location = window.location as any;
      location.reload(true);
    }
  };
};

export const useServiceWorker = () => {
  const handleMessage = useHandleMessage();
  const sha = getGitCommitSha();

  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname === "surfboard.live" || hostname === "surfboard.team") {
      if ("serviceWorker" in navigator) {
        const wb = new Workbox("/sw.js");
        wb.register();
      }
    }
  }, []);

  useEffect(() => {
    navigator.serviceWorker.addEventListener("message", handleMessage);
    return () => {
      navigator.serviceWorker.removeEventListener("message", handleMessage);
    };
  }, [sha, handleMessage]);
};
