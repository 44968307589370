const companyDomains = [
  "daommo.com",
  "get-surfboard.com",
  "surfboard.email",
  "surfboard.live",
  "surfboard.team",
];

export const automatedTestUserEmails = [
  "testadmin@surfboard.live",
  "testmember@surfboard.live",
  "testadmin@surfboard.team",
  "testmember@surfboard.team",
];

export const employeePersonalTestEmails = [
  "billli@gmail.com",
  "nathaniel.a.hill@gmail.com",
  "therealchuhan@gmail.com",
  "rdgudka@aol.com",
  "rory.gudka@gmail.com",
  "alexandru.mihai.se@gmail.com",
  "angeladu2020@gmail.com",
  "ashleywang.design@gmail.com",
  "jiaomatthew22@gmail.com",
  "jiaomatthew222@gmail.com",
  "jiaomatthew2222@gmail.com",
  "jiaomatthew22222@gmail.com",
  "mihai.v.alexandru@gmail.com",
  "zhengwt@gmail.com",
  "rdgudka.jp@gmail.com",
  "maxhuangspam@gmail.com",
];

export const isExcludedAnalyticsUser = (
  userName: string,
  environment: string,
) => {
  // No analytics should ever be sent outside of staging or production
  // (this is redundant for cleanliness of data)
  if (environment !== "staging" && environment !== "production") return true;

  // Always exclude noisy test accounts in any environment
  for (const automatedTestUserEmail of automatedTestUserEmails) {
    if (userName === automatedTestUserEmail) return true;
  }

  // For now we will report all users in staging for development
  // When we are satisfied we will set analytics for production only!
  if (environment === "staging") return false;

  // Exclude employee testing emails to reduce analytics noise
  // (we are not using the product with the same behavior as real users)
  for (const employeePersonalTestEmail of employeePersonalTestEmails) {
    if (userName === employeePersonalTestEmail) return true;
  }

  // Exclude employee company emails for same reason as above
  for (const companyDomain of companyDomains) {
    if (userName.endsWith(`@${companyDomain}`)) return true;
  }

  return false;
};
