import { useIsPublic, useParams } from "@/libs/hooksLib";

import { usePathname } from "next/navigation";
import { useRouter } from "next/router";

export const useAppendQueryParameters = () => {
  const router = useRouter();
  const curParams = useParams();
  const pathname = usePathname();
  return (paramsToAdd: { [param: string]: string }) => {
    const newParams = { ...curParams, ...paramsToAdd };
    const newParamsStr = Object.entries(newParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const queryStr = newParamsStr ? `?${newParamsStr}` : "";
    router.push(`${pathname}${queryStr}`, null, { scroll: false });
  };
};

export const useReplaceQueryParams = () => {
  const router = useRouter();
  const pathname = usePathname();
  return (paramsToReplace: { [param: string]: string }) => {
    const replacedQueryStringParams = Object.entries(paramsToReplace)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const queryStr = replacedQueryStringParams
      ? `?${replacedQueryStringParams}`
      : "";
    router.push(`${pathname}${queryStr}`, null, { scroll: false });
  };
};

export const useRemoveQueryParameters = () => {
  const router = useRouter();
  const curParams = useParams();
  const pathname = usePathname();
  return (paramsToRemove: string[]) => {
    const newParams = { ...curParams };
    paramsToRemove.forEach((param) => delete newParams[param]);
    const newParamsStr = Object.entries(newParams)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");
    const queryStr = newParamsStr ? `?${newParamsStr}` : "";
    router.replace(`${pathname}${queryStr}`, null, { scroll: false });
  };
};

export const useIsEditing = () => {
  const router = useRouter();
  return router.query.edit && router.query.edit === "true";
};

export const useSetIsEditing = () => {
  const isPublic = useIsPublic();
  const appendQueryParameters = useAppendQueryParameters();
  const removeQueryParameters = useRemoveQueryParameters();
  return (isEdit: boolean = true) => {
    isEdit && !isPublic
      ? appendQueryParameters({ edit: "true" })
      : removeQueryParameters(["edit"]);
  };
};

export const useSelectedView = (defaultValues: { defaultTab: string }) => {
  const router = useRouter();
  return (router.query.view as string) || defaultValues.defaultTab;
};

export const useSetView = () => {
  const appendQueryParameters = useAppendQueryParameters();
  return (value: string) => {
    appendQueryParameters({ view: value });
  };
};
