import React, {
  DependencyList,
  Dispatch,
  EffectCallback,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Theme, useMediaQuery } from "@mui/material";
import { getBaseURL, getEnvironmentString } from "@/libs/environment";

import { useFeatureFlagEnabled } from "posthog-js/react";
import { useLocationContext } from "@/context/LocationContext";
import { useRouter } from "next/router";
import { useUrlTenantId } from "@/tenant/queries";

/**
 * Same as useEffect, but only triggers the effect after the dependencies haven't changed for a certain period of time,
 * and also doesn't trigger on initial render.
 *
 * @param delay in milliseconds
 *
 */
export function useDebouncedEffect(
  effect: () => void,
  delay: number,
  deps: DependencyList,
) {
  return useEffectAfterInitial(() => {
    const handler = setTimeout(effect, delay);
    return () => clearTimeout(handler);
  }, deps);
}

export function useTriggerDebounceStateUpdate() {
  const [debounceState, setDebounceState] = useState(false);
  const triggerDebounce = () => setDebounceState(!debounceState);
  return { debounceState, triggerDebounce };
}

export function useRouteDependentState<T>(
  valueSetter: () => T,
): [T, (t: T | ((prev: T) => T)) => void] {
  const router = useRouter();
  const [state, setState] = useState<T>(valueSetter());

  useEffect(() => {
    setState(valueSetter());
  }, [router.asPath, valueSetter]);

  return [state, setState];
}
/**
 * Same as useEffect, but doesn't trigger on initial render.
 */
export function useEffectAfterInitial(
  effect: EffectCallback,
  deps: DependencyList,
) {
  const firstRender = useRef(true);
  return useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      return effect();
    }
  }, deps);
}

export const useEffectOnlyOnce = (
  callback: () => any,
  dependencies: any[],
  condition: boolean,
) => {
  const calledOnce = React.useRef(false);

  useEffect(() => {
    if (calledOnce.current) {
      return;
    }
    if (condition) {
      callback();
      calledOnce.current = true;
    }
  }, [callback, condition, dependencies]);
};

export function useIsMobile() {
  const response = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("md"),
  );
  const [isMobile, setIsMobile] = useState(response);
  useEffect(() => {
    setIsMobile(response);
  }, [response]);
  return isMobile;
}

export function usePushTenantHistory() {
  const router = useRouter();
  const urlTenantId = useUrlTenantId();
  return async (
    url: string,
    queryParams: {
      [key: string]: string;
    } = {},
  ) => {
    const urlQueryParams = getQueryParamsFromUrl(url);
    const allQueryParams = {
      ...urlQueryParams,
      ...queryParams,
    };
    const queryParamsStr = Object.entries(allQueryParams)
      .filter(([_, value]) => !!value)
      .map(([key, value]) => `${key}=${value}`)
      .join("&");

    const to = `/${urlTenantId}${removeQueryParamsFromUrl(url)}${
      queryParamsStr ? `?${queryParamsStr}` : ""
    }`;
    if (router.asPath === to) return;
    await router.push(to);
    window.scrollTo(0, 0);
  };
}

function getQueryParamsFromUrl(url: string) {
  const queryParams = url.split("?")[1];
  if (!queryParams) return {};
  return Object.fromEntries(
    queryParams.split("&").map((param) => param.split("=")),
  );
}

function removeQueryParamsFromUrl(url: string) {
  return url.split("?")[0];
}

export function useDeviceRequiresTouch() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}
export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);
  return useCallback(() => isMounted.current, []);
}

export function useIsVisible(ref: any) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    if (!ref || !ref.current) return;
    const observer = new IntersectionObserver(([entry]) =>
      setIntersecting(entry.isIntersecting),
    );

    observer.observe(ref.current);
  }, [ref]);

  return isIntersecting;
}

export const useIsPublic = () => {
  return usePathname().split("/")[1] === "public";
};

export function useTenantIdFromUrl() {
  const isPublic = useIsPublic();
  const paths = usePathname().split("/");
  const tenantId = paths[isPublic ? 2 : 1];

  if (!isPublic && paths.length === 2) {
    return "";
  }

  return (tenantId.match(/-/g) || []).length === 1
    ? tenantId.substring(tenantId.indexOf("-") + 1)
    : tenantId;
}

function useLocationFromUrl() {
  const isPublic = useIsPublic();
  const paths = usePathname().split("/");
  return paths[isPublic ? 3 : 2];
}

export function useLocation() {
  const { location } = useLocationContext();
  const urlLocation = useLocationFromUrl();
  return location || urlLocation;
}

function useDocumentIdFromUrl() {
  const isPublic = useIsPublic();
  const paths = usePathname().split("/");
  return paths[isPublic ? 4 : 3];
}

export function useDocumentId() {
  const { documentId } = useLocationContext();
  const urlDocumentId = useDocumentIdFromUrl();
  return documentId || urlDocumentId;
}

export function useParams() {
  const router = useRouter();
  const routerParams = Object.fromEntries(
    router.asPath
      ?.split("?")[1]
      ?.split("&")
      .map((param) => param.split("=")) || [],
  );

  const { params } = useLocationContext();
  return (params || routerParams) as { [key: string]: string };
}

export function useKeyPress(
  targetKey: string,
  onPress?: () => void,
  options?: { ctrl?: boolean; shift?: boolean; preventDefault?: boolean },
) {
  const isMac = useIsMac();
  const [keyPressed, setKeyPressed] = useState<boolean>(false);

  useEffect(() => {
    function downHandler(e: KeyboardEvent) {
      if (!e.defaultPrevented) {
        const ctrlKey = isMac ? e.metaKey : e.ctrlKey;
        if (
          e.key?.toLowerCase() === targetKey.toLowerCase() &&
          (typeof options?.ctrl !== "boolean" || ctrlKey === options.ctrl) &&
          (typeof options?.shift !== "boolean" || e.shiftKey === options.shift)
        ) {
          if (options?.preventDefault) e.preventDefault();
          setKeyPressed(true);
          onPress();
        }
      }
    }

    const upHandler = (e: KeyboardEvent) => {
      if (e.key?.toLowerCase() === targetKey.toLowerCase()) {
        setKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);
    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, [targetKey, options, isMac, onPress]);

  return keyPressed;
}

export const useIsMac = () => {
  const [isMac, setIsMac] = useState(false);
  useEffect(() => {
    setIsMac(navigator.platform.toUpperCase().indexOf("MAC") >= 0);
  }, []);
  return isMac;
};

export const useIsFirefox = () => {
  const [isFirefox, setIsFirefox] = useState(false);
  useEffect(() => {
    setIsFirefox(navigator.userAgent.includes("Firefox"));
  }, []);
  return isFirefox;
};

export const useIsDvhSupported = () => {
  const [isDvhSupported, setIsDvhSupported] = useState(false);
  useEffect(() => {
    setIsDvhSupported(CSS.supports("height:100dvh"));
  }, []);
  return isDvhSupported;
};

export const useUrl = () => {
  const router = useRouter();
  const { asPath } = useLocationContext();
  const origin = getBaseURL();
  return `${origin}${asPath || router.asPath}`;
};

export const usePathname = () => {
  const router = useRouter();
  const asPath = useLocationContext()?.asPath || router.asPath;
  const index = asPath.indexOf("?");
  return index === -1 ? asPath : asPath.substring(0, index);
};

/**
 * For same paths, when trying to display different views (for example selecting a different tab), use the ?view=... query param
 * This hook returns the view name from the query param
 */
export const useViewName = () => {
  const { view } = useParams();
  return view;
};

export const useSize = () => {
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [element, setElement] = useState(null);
  const [observer] = useState(
    new ResizeObserver((entries) => {
      setSize({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      });
    }),
  );

  useEffect(() => {
    return () => {
      observer.disconnect();
    };
  }, [observer]);

  const ref = useCallback(
    (element: any) => {
      setElement(element);
      if (element) {
        const { width, height } = element.getBoundingClientRect();
        setSize({ width, height });
        observer.observe(element);
      }
    },
    [observer],
  );

  return { ref, element, size };
};

export function useOutsideAlerter(
  ref: React.RefObject<any>,
  callback: () => void,
  options?: { trigger: "mousedown" | "mouseup" },
) {
  const trigger = options?.trigger || "mousedown";
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener(trigger, handleClickOutside);
    return () => document.removeEventListener(trigger, handleClickOutside);
  }, [ref, callback, trigger]);
}

export function useMultiOutsideAlerter(
  inputs: [React.RefObject<any>, () => void][],
  options?: { trigger: "mousedown" | "mouseup" },
) {
  const trigger = options?.trigger || "mousedown";
  useEffect(() => {
    function handleClickOutside(event: any) {
      inputs.forEach(([ref, callback]) => {
        if (ref.current && !ref.current.contains(event.target)) {
          callback();
        }
      });
    }

    document.addEventListener(trigger, handleClickOutside);
    return () => document.removeEventListener(trigger, handleClickOutside);
  }, [inputs, trigger]);
}

export const useScreenSize = () => {
  const curWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
  const curHeight = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0,
  );

  const [screenWidth, setScreenWidth] = useState(curWidth);
  const [screenHeight, setScreenHeight] = useState(curHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(curWidth);
      setScreenHeight(curHeight);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [curWidth, curHeight]);

  return { screenWidth, screenHeight };
};

export const useShowExpandedCommentsInline = () => {
  const environment = getEnvironmentString();
  const location = useLocation();
  const expandedCommentsEnabled =
    useFeatureFlagEnabled("expanded-comments") || environment === "local";
  const { screenWidth } = useScreenSize();

  return (
    !expandedCommentsEnabled || screenWidth < 1800 || location === "meeting"
  );
};

interface StateFromQueryValue<S> {
  queryString: string;
  defaultValue: S;
  dependencyValue: any;
}

export const useStateWithInitialQueryValue = <S>({
  queryString,
  defaultValue,
  dependencyValue,
}: StateFromQueryValue<S>): [S, Dispatch<SetStateAction<S>>] => {
  const router = useRouter();
  const queryStringValue = router.query[queryString] as S;
  const initialValue = queryStringValue
    ? (queryStringValue as string).split(",")
    : defaultValue;
  const [local, setLocal] = useState<S>(null);
  const value = local || initialValue;

  useEffect(() => {
    return () => {
      setLocal(null);
    };
  }, [dependencyValue]);

  return [value as S, setLocal];
};
