import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { useEffect } from "react";
import { useSetToast } from "@/store/transitionStore";
import { useUserStore } from "@/user/userStore";

interface GmailApiStore {
  isAuthenticated: boolean;
  tokenClient: any;
  setTokenClient: (tokenClient: any) => void;
  setAuthenticated: (isAuthenticated: boolean) => void;
}

export const useGmailApiStore = create<GmailApiStore>((set) => ({
  tokenClient: null,
  isAuthenticated: false,
  setTokenClient: (tokenClient) => set({ tokenClient }),
  setAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
}));

export const useInitGmailApi = () => {
  const setTokenClient = useGmailApiStore((state) => state.setTokenClient);
  const setAuthenticated = useGmailApiStore((state) => state.setAuthenticated);

  useEffect(() => {
    if (typeof document === "undefined") return;

    if (
      !process.env.NEXT_PUBLIC_GCP_EMAIL_API_KEY ||
      !process.env.NEXT_PUBLIC_GCP_EMAIL_CLIENT_ID
    ) {
      console.info("Missing Google API Key or Client ID");
      return;
    }

    (async () => {
      await loadScriptInsideDOM(
        "https://apis.google.com/js/api.js",
        "google-api",
      );
      await loadScriptInsideDOM(
        "https://accounts.google.com/gsi/client",
        "google-identity-service",
      );
      //@ts-ignore
      gapi.load(
        "client",
        async () =>
          //@ts-ignore
          await gapi.client.init({
            apiKey: process.env.NEXT_PUBLIC_GCP_EMAIL_API_KEY,
            discoveryDocs: [
              "https://www.googleapis.com/discovery/v1/apis/gmail/v1/rest",
            ],
          }),
      );

      setTokenClient(
        //@ts-ignore
        google.accounts.oauth2.initTokenClient({
          client_id: process.env.NEXT_PUBLIC_GCP_EMAIL_CLIENT_ID,
          scope: "https://www.googleapis.com/auth/gmail.send",
          callback: "",
        }),
      );
    })();

    () => {
      //@ts-ignore
      const token = gapi.client.getToken();
      if (token !== null) {
        //@ts-ignore
        google.accounts.oauth2.revoke(token.access_token);
        //@ts-ignore
        gapi.client.setToken(null);
      }
    };
  }, [setAuthenticated, setTokenClient]);
};

const loadScriptInsideDOM = async function (src: string, id: string) {
  return new Promise((resolve) => {
    const element = document.getElementsByTagName("script")[0];
    const js = document.createElement("script");
    js.id = id;
    js.src = src;
    js.async = true;
    js.defer = true;
    element.parentNode.insertBefore(js, element);
    js.onload = async () => {
      resolve("loaded");
    };
  });
};

export const useIsGmailAuthenticated = () => {
  return useGmailApiStore((state) => state.isAuthenticated);
};

export const useAuthGmailAndSend = ({
  address,
  subject,
  body,
}: {
  address: string;
  subject: string;
  body: string;
}) => {
  const currentUserEmail = useUserStore((state) => state.user?.email, shallow);
  const authGmail = useAuthenticateGmail();
  const setToast = useSetToast();
  return async () => {
    try {
      await authGmail();
    } catch (e) {
      console.error(e);
      setToast("An error occurred while sending the email");
    }

    const rawEmail = Buffer.from(
      `From: ${currentUserEmail}\n` +
        `To: ${address}\n` +
        `Subject: ${subject}\n\n` +
        body,
    )
      .toString("base64")
      .replace(/\+/g, "-")
      .replace(/\//g, "_");

    //@ts-ignore
    await gapi.client.gmail.users.messages.send({
      userId: "me",
      raw: rawEmail,
    });

    setToast("Email sent successfully", "success");
  };
};

export const useAuthenticateGmail = () => {
  const tokenClient = useGmailApiStore((state) => state.tokenClient);
  const setAuthenticated = useGmailApiStore((state) => state.setAuthenticated);
  return async () => {
    const authResponse = new Promise((resolve, reject) => {
      tokenClient.callback = async (resp: any) => {
        if (resp.error !== undefined) {
          reject(resp);
        }
        setAuthenticated(true);
        resolve(resp);
      };
    });
    //@ts-ignore
    if (gapi.client.getToken() === null) {
      tokenClient.requestAccessToken({ prompt: "consent" });
    } else {
      tokenClient.requestAccessToken({ prompt: "" });
    }

    await authResponse;
  };
};
