const filters: {
  [key: string]: {
    exclude?: string[];
  };
} = {
  posthog: {
    exclude: [],
  },
  customer_io: {
    exclude: ["folder"],
  },
};

export const analyticsDestinations = ["posthog"];

export type EventDestination = (typeof analyticsDestinations)[number];

export const isExcludedEvent = (dest: EventDestination, eventName: string) => {
  const expressions = filters[dest].exclude;
  if (!expressions) return false;

  for (const expression of expressions) {
    const re = new RegExp(expression);
    if (re.test(eventName)) return true;
  }

  return false;
};
