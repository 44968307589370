import "@/lib/analytics/init";
import "@/styles/DataRoom.css";
import "@/styles/Editor.css";
import "@/styles/Global.css";
import "@/styles/Meeting.css";
import "@/styles/MobileMembers.css";
import "@/styles/Toolbar.css";
import "regenerator-runtime/runtime";

import * as Sentry from "@sentry/nextjs";

import { CacheProvider, EmotionCache } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "react-query";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Amplify } from "@aws-amplify/core";
import type { AppProps } from "next/app";
import AppWrapper from "@/page_wrappers/AppWrapper";
import { Auth } from "@aws-amplify/auth";
import { BannerProvider } from "@/context/BannerContext";
import Head from "next/head";
import { IntercomProvider } from "react-use-intercom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { PostHogProvider } from "posthog-js/react";
import SurfbreakPage from "@/components/SurfbreakPage";
import { ThemeProvider } from "@mui/system";
import authPages from "@/AuthPages";
import config from "@/config";
import createEmotionCache from "@/createEmotionCache";
import dynamic from "next/dynamic";
import posthog from "posthog-js";
import { theme } from "@/theme";
import { useIsPublic } from "@/libs/hooksLib";
import { useRouter } from "next/router";
import { useServiceWorker } from "@/libs/serviceWorker";

const AuthenticatedRoute = dynamic(
  () => import("@/components/AuthenticatedRoute"),
);
const TenantWrapper = dynamic(() => import("@/tenant/wrapper/TenantWrapper"));
const PublicTemplatesProvider = dynamic(
  () => import("@/context/PublicTemplatesContext"),
);
const PublicRoute = dynamic(() => import("@/components/PublicRoute"));
const PublicWrapper = dynamic(() => import("@/page_wrappers/PublicWrapper"));

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.region,
    userPoolId: config.userPoolId,
    identityPoolId: config.identityPoolId,
    userPoolWebClientId: config.userPoolClientId,
    redirectSignIn: config.redirectSignIn,
    redirectSignOut: config.redirectSignOut,
    oauth: {
      domain: config.userPoolDomain,
      scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
      redirectSignIn: config.redirectSignIn,
      redirectSignOut: config.redirectSignOut,
      responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
  API: {
    endpoints: [
      {
        name: "api",
        endpoint: config.apiUrl,
        region: config.region,
        custom_header: async () => {
          try {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getIdToken()
                .getJwtToken()}`,
            };
          } catch (err) {
            return {};
          }
        },
      },
    ],
  },
});

const queryClient = new QueryClient();
const clientSideEmotionCache = createEmotionCache();

interface SurfboardAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

const App = ({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}: SurfboardAppProps) => {
  const router = useRouter();
  const paths = router.pathname.split("/");
  const isPublic = useIsPublic();
  const isTenant = authPages.includes(paths[1]) || authPages.includes(paths[2]);

  useServiceWorker();

  return (
    <>
      <Head>
        <title key="title">Surfboard | Board Management Software</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
      </Head>
      <IntercomProvider autoBoot={false} appId="ubbtj9uh">
        <Sentry.ErrorBoundary
          showDialog={false}
          fallback={SurfbreakPage}
          beforeCapture={(scope) => {
            scope.setTag("location", "_appErrorBoundary");
          }}
        >
          <PostHogProvider client={posthog}>
            <CacheProvider value={emotionCache}>
              <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <BannerProvider>
                      <AppWrapper>
                        {isTenant ? (
                          <AuthenticatedRoute>
                            <TenantWrapper>
                              <Component {...pageProps} />
                            </TenantWrapper>
                          </AuthenticatedRoute>
                        ) : isPublic ? (
                          <PublicTemplatesProvider>
                            <PublicRoute>
                              <PublicWrapper>
                                <Component {...pageProps} />
                              </PublicWrapper>
                            </PublicRoute>
                          </PublicTemplatesProvider>
                        ) : (
                          <Component {...pageProps} />
                        )}
                      </AppWrapper>
                    </BannerProvider>
                  </LocalizationProvider>
                </ThemeProvider>
              </QueryClientProvider>
            </CacheProvider>
          </PostHogProvider>
        </Sentry.ErrorBoundary>
      </IntercomProvider>
    </>
  );
};

export default App;
