import { Alert, Box, Snackbar } from "@mui/material";
import {
  useCloseToast,
  useHandleNotificationsClose,
  useTransitionStore,
} from "@/store/transitionStore";

import dynamic from "next/dynamic";
import { mobileSx } from "@/theme";

const LoadingBox = dynamic(() => import("@/components/LoadingBox"));

const NotificationsPopup = dynamic(
  () => import("@/notifications/NotificationsPopup"),
);

export const Transition = () => {
  const isTransitioning = useTransitionStore((state) => state.transition);

  const toast = useTransitionStore((state) => state.toast);
  const closeToast = useCloseToast();

  const notificationsPopup = useTransitionStore(
    (state) => state.notificationsPopup,
  );
  const handleNotificationsClose = useHandleNotificationsClose();

  return (
    <>
      {/** Loading Screen */}
      {isTransitioning && (
        <Box
          position="absolute"
          left={0}
          right={0}
          top={0}
          bottom={0}
          display={"flex"}
          alignItems="center"
          justifyContent="center"
          style={{ backgroundColor: "white" }}
          zIndex={1201} //Drawer is 1200
        >
          <LoadingBox />
        </Box>
      )}

      {/** Success / Failure toast */}
      <Snackbar
        open={toast.open}
        autoHideDuration={3000}
        onClose={closeToast}
        sx={{ justifyContent: "center", ...mobileSx({ width: "100%" }) }}
        anchorOrigin={
          toast.top
            ? { vertical: "top", horizontal: "center" }
            : { vertical: "bottom", horizontal: "center" }
        }
      >
        <Alert
          elevation={6}
          variant="filled"
          onClose={closeToast}
          severity={toast.severity}
          icon={toast.icon && <span role="img">{toast.icon}</span>}
          sx={{
            color: "white",
            alignItems: "center",
            borderRadius: "48px",
            py: "0",
            height: "40px",
            ...mobileSx({ height: "32px" }),
          }}
        >
          <Box
            component="span"
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              lineHeight: "20.8px",
              ...mobileSx({ fontSize: "12px", lineHeight: "15.6px" }),
            }}
          >
            {toast.message}
          </Box>
        </Alert>
      </Snackbar>

      {notificationsPopup.open && (
        <NotificationsPopup
          onClose={handleNotificationsClose}
          {...notificationsPopup}
        />
      )}
    </>
  );
};
