/**  pages only logged in users can see */
export const authPages = [
  "ask",
  "discussion",
  "meeting",
  "approval",
  "messages",
  "dataRoom",
  "sentiments",
  "sentiment",
  "management",
  "billing",
  "members",
  "profile",
  "dashboard",
  "tasks",
  "notifications",
  "quickstart",
  "imports",
  "workspace_connections",
  "my_connections",
  "email",
];

export default authPages;
