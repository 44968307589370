import React from "react";
import StatusComponent from "@/components/StatusComponent";
import { Text } from "@/components/Text";

const SurfbreakPage: React.FunctionComponentFactory<{
  error?: any;
  isTenantError?: boolean;
}> = ({ error, isTenantError = false }) => {
  console.debug(error, isTenantError);

  return (
    <StatusComponent
      title="We hit a sudden surf break"
      body={
        <div>
          <Text>We had trouble loading this page.</Text>
          <Text>Refresh this page or go back to the previous page.</Text>
          <Text>
            If you keep seeing this error,{" "}
            <a href="mailto:help@surfboard.team">let us know.</a>
          </Text>
        </div>
      }
      button={{ text: "Refresh page", onClick: () => location.reload() }}
    />
  );
};

export default SurfbreakPage;
