import {
  ProfileProps,
  TenantOverviews,
  TenantPayload,
  User,
} from "@/user/model";
import { useEffect, useRef } from "react";

import { EmailNotificationSettings } from "@/unsubscribe/models";
import { create } from "zustand";
import { shallow } from "zustand/shallow";

interface UserStore {
  user: User;
  setUser: (user: User) => void;
  /**
   * Used only for joining surfboard
   */
  cognitoUser: any;
  setCognitoUser: (cognitoUser: any) => void;
  /**
   * To remove, just used to confirm signup.. should be used in the handler that signs up the user
   */
  key: string;
  setKey: (key: string) => void;
  loading: boolean;
  isAuthenticated: () => boolean;
  setLoading: (loading: boolean) => void;
  setOnboardingDone: (onboardingDone: boolean) => void;
  setSignature: (signature: string) => void;
  setCredentialsUsed: (credentialsUsed: boolean) => void;
  acceptInvite: (tenantId: string) => void;
  setTenantOverviews: (tenantOverviews: TenantOverviews) => void;
  setTenantLegalName: (tenantId: string, legalName: string) => void;
  setTenantName: (tenantId: string, name: string) => void;
  setEmptyUser: () => void;
  setUserPrefs: (prefs: EmailNotificationSettings) => void;
  setUserName: (name: string) => void;
  setProfile: (profileProps: ProfileProps) => void;
  hasBoards: () => boolean;
  setAsksTableView: (asksTableView: boolean) => void;
}

export const useUserStore = create<UserStore>((set, get) => ({
  user: {
    id: "",
    name: "",
    email: "",
    avatarUrl: "",
    lastTenant: "",
    onboardingDone: false,
    showObjective: false,
    prefs: {},
    tenants: [] as TenantPayload[],
    invites: [] as TenantPayload[],
    asksTableView: false,
    credentialsUsed: false,
    unreadOnlyPreference: false,
  },
  setUser: (user) => set({ user }),
  cognitoUser: null,
  setCognitoUser: (cognitoUser) => set({ cognitoUser }),
  key: "",
  setKey: (key) => set({ key }),
  loading: true,
  isSigningOut: false,
  isAuthenticated: () => {
    return !!get().user.id;
  },
  setLoading: (loading) => set({ loading }),
  setOnboardingDone: (onboardingDone) =>
    set((state) => ({
      user: {
        ...state.user,
        onboardingDone,
      },
    })),
  setSignature: (signature) =>
    set((state) => ({
      user: {
        ...state.user,
        signature,
      },
    })),
  setCredentialsUsed: (credentialsUsed) =>
    set((state) => ({
      user: {
        ...state.user,
        credentialsUsed,
      },
    })),
  acceptInvite: (tenantId) =>
    set((state) => {
      let invites = [...state.user.invites];
      const tenants = [...state.user.tenants];
      const acceptedWorkspaceInvite = invites.find(
        (invite) => invite.TenantId === tenantId,
      );
      if (acceptedWorkspaceInvite) {
        invites = invites.filter((invite) => invite.TenantId !== tenantId);
        tenants.push(acceptedWorkspaceInvite);
      }
      return {
        user: {
          ...state.user,
          invites: invites,
          tenants: tenants,
        },
      };
    }),

  setTenantOverviews: (tenantOverviews) =>
    set((state) => {
      const updatedTenants = state.user.tenants.map((tenant) => {
        const tenantOverview = tenantOverviews[tenant.TenantId];
        let updated = tenant;
        if (tenantOverview) {
          updated = {
            ...tenant,
            ...tenantOverview,
          };
        }
        return updated;
      });
      return {
        user: {
          ...state.user,
          tenants: updatedTenants,
        },
      };
    }),

  setTenantLegalName: (tenantId, legalName) =>
    set((state) => ({
      user: {
        ...state.user,
        tenants: state.user.tenants.map((tenant) => {
          if (tenant.TenantId === tenantId) {
            tenant.TenantLegalName = legalName;
          }
          return tenant;
        }),
      },
    })),
  setTenantName: (tenantId, name) =>
    set((state) => ({
      user: {
        ...state.user,
        tenants: state.user.tenants.map((tenant) => {
          if (tenant.TenantId === tenantId) {
            tenant.TenantName = name;
          }
          return tenant;
        }),
      },
    })),

  setEmptyUser: () =>
    set({
      user: {
        id: "",
        name: "",
        email: "",
        avatarUrl: "",
        lastTenant: "",
        onboardingDone: false,
        showObjective: false,
        prefs: {},
        tenants: [] as TenantPayload[],
        invites: [] as TenantPayload[],
        asksTableView: false,
        credentialsUsed: false,
        unreadOnlyPreference: false,
      },
    }),

  setUserPrefs: (prefs) =>
    set((state) => ({
      user: {
        ...state.user,
        prefs,
      },
    })),

  setUserName: (name) => set((state) => ({ user: { ...state.user, name } })),

  setProfile: ({ tenantId, name, title, company }) =>
    set((state) => ({
      user: {
        ...state.user,
        tenants: state.user.tenants.map((tenant) => {
          if (tenant.TenantId === tenantId) {
            tenant.TenantName = name;
            tenant.TenantLegalName = company;
          }
          return tenant;
        }),
        name,
        title,
        company,
      },
    })),

  setAsksTableView: (asksTableView) =>
    set((state) => ({
      user: {
        ...state.user,
        asksTableView,
      },
    })),

  hasBoards: () => {
    const { user } = get();
    return user.tenants && user.tenants.length > 0;
  },
}));

export const useSetUser = () => {
  const setUserRef = useRef(useUserStore.getState().setUser);

  useEffect(() => {
    useUserStore.subscribe((state) => (setUserRef.current = state.setUser));
  }, []);

  return setUserRef.current;
};

export const useSetCognitoUser = () => {
  const setCognitoUserRef = useRef(useUserStore.getState().setCognitoUser);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setCognitoUserRef.current = state.setCognitoUser),
    );
  }, []);

  return setCognitoUserRef.current;
};

export const useSetKey = () => {
  const setKeyRef = useRef(useUserStore.getState().setKey);

  useEffect(() => {
    useUserStore.subscribe((state) => (setKeyRef.current = state.setKey));
  }, []);

  return setKeyRef.current;
};

export const useSetLoading = () => {
  const setLoadingRef = useRef(useUserStore.getState().setLoading);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setLoadingRef.current = state.setLoading),
    );
  }, []);

  return setLoadingRef.current;
};

export const useSetOnboardingDone = () => {
  const setOnboardingDoneRef = useRef(
    useUserStore.getState().setOnboardingDone,
  );

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setOnboardingDoneRef.current = state.setOnboardingDone),
    );
  }, []);

  return setOnboardingDoneRef.current;
};

export const useSetUserSignature = () => {
  const setSignatureRef = useRef(useUserStore.getState().setSignature);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setSignatureRef.current = state.setSignature),
    );
  }, []);

  return setSignatureRef.current;
};

export const useSetCredentialsUsed = () => {
  const setCredentialsUsedRef = useRef(
    useUserStore.getState().setCredentialsUsed,
  );

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setCredentialsUsedRef.current = state.setCredentialsUsed),
    );
  }, []);

  return setCredentialsUsedRef.current;
};

export const useAcceptUserInvite = () => {
  const acceptInviteRef = useRef(useUserStore.getState().acceptInvite);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (acceptInviteRef.current = state.acceptInvite),
    );
  }, []);

  return acceptInviteRef.current;
};

export const useSetTenantOverviews = () => {
  const setTenantOverviewsRef = useRef(
    useUserStore.getState().setTenantOverviews,
  );

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setTenantOverviewsRef.current = state.setTenantOverviews),
    );
  }, []);

  return setTenantOverviewsRef.current;
};

export const useSetTenantLegalName = () => {
  const setTenantLegalNameRef = useRef(
    useUserStore.getState().setTenantLegalName,
  );

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setTenantLegalNameRef.current = state.setTenantLegalName),
    );
  }, []);

  return setTenantLegalNameRef.current;
};

export const useSetTenantName = () => {
  const setTenantNameRef = useRef(useUserStore.getState().setTenantName);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setTenantNameRef.current = state.setTenantName),
    );
  }, []);

  return setTenantNameRef.current;
};

export const useSetEmptyUser = () => {
  const setEmptyUserRef = useRef(useUserStore.getState().setEmptyUser);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setEmptyUserRef.current = state.setEmptyUser),
    );
  }, []);

  return setEmptyUserRef.current;
};

export const useSetUserPrefs = () => {
  const setUserPrefsRef = useRef(useUserStore.getState().setUserPrefs);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setUserPrefsRef.current = state.setUserPrefs),
    );
  }, []);

  return setUserPrefsRef.current;
};

export const useSetUserName = () => {
  const setUserNameRef = useRef(useUserStore.getState().setUserName);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setUserNameRef.current = state.setUserName),
    );
  }, []);

  return setUserNameRef.current;
};

export const useSetUserProfile = () => {
  const setProfileRef = useRef(useUserStore.getState().setProfile);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (setProfileRef.current = state.setProfile),
    );
  }, []);

  return setProfileRef.current;
};

export const useHasBoards = () => {
  const hasBoardsRef = useRef(useUserStore.getState().hasBoards);

  useEffect(() => {
    useUserStore.subscribe((state) => (hasBoardsRef.current = state.hasBoards));
  }, []);

  return hasBoardsRef.current;
};

export const useIsAuthenticated = () => {
  const isAuthenticatedRef = useRef(useUserStore.getState().isAuthenticated);

  useEffect(() => {
    useUserStore.subscribe(
      (state) => (isAuthenticatedRef.current = state.isAuthenticated),
    );
  }, []);

  return isAuthenticatedRef.current;
};

export const useGetAsksTableView = () => {
  return useUserStore((state) => state.user.asksTableView, shallow);
};
