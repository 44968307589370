import { SxProps, lighten } from "@mui/system";

import { createTheme } from "@mui/material/styles";
import localFont from "next/font/local";
import { outlinedInputClasses } from "@mui/material/OutlinedInput";

// Module augmentation for custom colors.
// See https://material-ui.com/customization/palette/#adding-new-colors
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    offwhite: Palette["primary"];
    brown: Palette["primary"];
    darkGrey: Palette["primary"];
    lightTeal: Palette["primary"];
  }
  interface PaletteOptions {
    offwhite: PaletteOptions["primary"];
    brown: PaletteOptions["primary"];
    darkGrey: PaletteOptions["primary"];
    lightTeal: PaletteOptions["primary"];
  }
}

const darkGray = "#707781";
const typeBrown = "#5F5046";
const darkTeal = "#0F727D";

export const MuseoSans = localFont({
  src: [
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-100.woff2",
      weight: "100",
      style: "normal",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-100Italic.woff2",
      weight: "100",
      style: "italic",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-300.woff2",
      weight: "300",
      style: "normal",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-300Italic.woff2",
      weight: "300",
      style: "italic",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-500.woff2",
      weight: "500",
      style: "normal",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-500Italic.woff2",
      weight: "500",
      style: "italic",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-700.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-700Italic.woff2",
      weight: "700",
      style: "italic",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-900.woff2",
      weight: "900",
      style: "normal",
    },
    {
      path: "../../../packages/fonts/MuseoSans/MuseoSansCyrl-900Italic.woff2",
      weight: "900",
      style: "italic",
    },
  ],
});

const CommutersSans = localFont({
  src: [
    {
      path: "../../../packages/fonts/CommutersSans/CommutersSans-Regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../../packages/fonts/CommutersSans/CommutersSans-SemiBold.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../../packages/fonts/CommutersSans/CommutersSans-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../../../packages/fonts/CommutersSans/CommutersSans-ExtraBoldItalic.woff2",
      weight: "800",
      style: "normal",
    },
  ],
});

const fallbackFonts = "Open Sans, Helvetica Neue, sans-serif";

export const defaultFont = `${MuseoSans.style.fontFamily}, ${fallbackFonts}`;
export const headingFont = `${CommutersSans.style.fontFamily}, ${fallbackFonts}`;

const baseTheme = createTheme({
  typography: {
    fontFamily: defaultFont,
    fontWeightRegular: 500,
    h1: {
      fontFamily: headingFont,
      fontSize: "2rem",
      lineHeight: "normal",
      fontWeight: 600,
    },
    h2: {
      fontSize: "1.5rem",
      lineHeight: 1.3,
      fontWeight: "normal",
      "@media only screen and (max-width: 600px)": {
        fontSize: "1.25rem",
        fontWeight: 700,
      },
    },
    h3: {
      fontSize: "1.125rem",
      lineHeight: 1.67,
      "@media only screen and (max-width: 600px)": {
        fontWeight: 700,
      },
    },
    h4: {
      fontSize: "1rem",
      lineHeight: 1.5,
      fontWeight: 700,
    },
    h5: {
      fontSize: "14px",
      lineHeight: 1.3,
      fontWeight: 600,
    },
    h6: {
      fontSize: "14px",
      lineHeight: 1.3,
      fontWeight: 400,
    },
    // body regular
    body1: {
      fontSize: "1rem",
      lineHeight: 1.3,
    },
    // body small regular
    body2: {
      fontSize: "0.875rem",
      lineHeight: 1.3,
    },
    // label large
    subtitle1: {
      fontWeight: 700,
      fontSize: "14px",
      lineHeight: "17px",
      letterSpacing: "0.3px",
      textTransform: "uppercase",
    },
    // label small
    subtitle2: {
      fontWeight: 900,
      fontSize: "12px",
      lineHeight: "14px",
      letterSpacing: "0.3px",
      textTransform: "uppercase",
    },
    button: {
      fontWeight: 700,
      fontSize: "1rem",
      lineHeight: "19.2px",
      textTransform: "none",
    },
  },
  palette: {
    primary: {
      main: "#F4673C", // orange
    },
    secondary: {
      light: lighten(darkTeal, 0.85),
      main: darkTeal,
      dark: "#04383E", // black teal (tertiary)
    },
    error: {
      main: "#E72323",
      light: lighten("#E72323", 0.8),
    },
    success: {
      main: "#69D272",
      light: lighten("#69D272", 0.8),
    },
    action: {
      active: darkGray,
      hover: "#FAF9F9",
    },
    background: {
      default: "#ffffff",
    },
    text: {
      primary: "#281305", // dark brown
      secondary: "#AFAEAC", // helper/template
      disabled: lighten(darkGray, 0.6),
    },
    divider: "#F3F4F5", //borders
    offwhite: {
      main: "#FFFAF8",
    },
    brown: {
      main: typeBrown,
      dark: "#281305",
    },
    darkGrey: {
      main: darkGray,
    },
    lightTeal: {
      main: "#C5D4D6",
    },
    info: {
      main: "#281305",
    },
    tonalOffset: 0.1,
  },
});

// construct new theme so we can use the theme in the component defaults
/**
 *  @deprecated You don't ever need to import this directly.
 *  Instead, use  https://mui.com/system/basics/#theme-getter
 *
 *  (We still use theme implicitly through a Context, so not actually deprecated)
 */
export const theme = createTheme(baseTheme, {
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: "h2",
          h2: "h2",
          h3: "h2",
          h4: "h2",
          h5: "h2",
          h6: "h2",
          subtitle1: "span",
          subtitle2: "span",
        },
      },
      styleOverrides: {
        overline: {
          fontSize: "1.25rem",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "outlined",
        color: "secondary",
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: "8px 20px 8px",
          transform: "translate3d(0,0,0)",
        },
        contained: {
          "&:focus-visible .MuiTouchRipple-root": {
            display: "none",
          },
        },
        outlined: {
          borderColor: baseTheme.palette.secondary.main,
          border: "0px",
          boxShadow: `0px 0px 0px 1px ${baseTheme.palette.secondary.main} inset`,
          "&:hover": {
            border: "0px",
            boxShadow: `0px 0px 0px 1px ${baseTheme.palette.secondary.main} inset`,
          },
          "&:focus-visible .MuiTouchRipple-root": {
            display: "none",
          },
        },
        text: {
          "&:focus-visible .MuiTouchRipple-root": {
            display: "none",
          },
        },
        textPrimary: {
          color: baseTheme.palette.secondary.main,
        },
        textSecondary: {
          color: baseTheme.palette.secondary.main,
        },
        outlinedPrimary: {
          color: typeBrown,
        },
        outlinedSecondary: {
          color: typeBrown,
        },
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: "outlined",
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: "8px 20px 8px",
        },
        text: {
          fontWeight: 500,
        },
        textPrimary: {
          color: baseTheme.palette.secondary.main,
        },
        outlinedPrimary: {
          color: typeBrown,
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
    },
    // Edit-in-place inputs
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          transition: `background-color ${baseTheme.transitions.duration.shortest}ms ${baseTheme.transitions.easing.easeInOut} 0ms`,
          borderRadius: "4px",
          boxSizing: "border-box",
          transform: "translate3d(0,0,0)",
          "&.Mui-error": {
            backgroundColor: baseTheme.palette.error.light,
          },
          "&.Mui-focused": {
            boxShadow: `0 0 0 1pt ${baseTheme.palette.action.focus}`, // border that doesn't affect layout
          },
          "&.Mui-disabled": {
            color: "inherit",
            backgroundColor: "inherit",
            "& .MuiInput-input": {
              WebkitTextFillColor: "unset",
            },
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "&.Mui-focused": {
            [`& .${outlinedInputClasses["notchedOutline"]}`]: {
              borderColor: typeBrown,
            },
          },
        },
        input: {
          paddingRight: "16px",
          paddingLeft: "16px",
        },
        notchedOutline: {
          borderWidth: "2px",
        },
      },
    },
    MuiMenu: {
      defaultProps: {
        elevation: 2,
      },
      styleOverrides: {
        root: {
          fontFamily: `${defaultFont}`,
        },
        paper: {
          border: `0.5px solid ${lighten(typeBrown, 0.55)}`,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: `${defaultFont}`,
          color: typeBrown,
          "&:hover, &.Mui-selected": {
            backgroundColor: `${baseTheme.palette.secondary.light} !important`,
          },
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        padding: "none",
      },
      styleOverrides: {
        root: {
          fontSize: "1rem",
        },
        head: {
          paddingBottom: `${baseTheme.spacing(1)}px !important`,
        },
        paddingNone: {
          paddingRight: baseTheme.spacing(2),
          paddingBottom: baseTheme.spacing(2),
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        outlined: {
          borderColor: lighten(darkGray, 0.8),
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        action: {
          padding: "0px 0px 0px 16px",
        },

        filledInfo: {
          backgroundColor: typeBrown,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          height: "32px",
          width: "32px",
          fontSize: "0.875rem",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        dot: {
          height: "10px",
          minWidth: "10px",
          borderRadius: "7.5px",
          border: "1.5px solid",
        },
      },
    },
    MuiSvgIcon: {
      defaultProps: {
        color: "inherit",
      },
      styleOverrides: {
        root: {
          "&.MuiSvgIcon-fontSizeSmall": {
            fontSize: "24px",
            width: "24px",
            height: "24px",
            viewBox: "0 0 24 24",
          },
          "&.MuiSvgIcon-fontSizeLarge": {
            fontSize: "32px",
            width: "32px",
            height: "32px",
            viewBox: "0 0 32 32",
          },
        },
      },
    },
    // font icons
    MuiIcon: {
      defaultProps: {
        color: "inherit",
      },
      styleOverrides: {
        root: {
          overflow: "initial",
          "&.MuiIcon-fontSizeSmall": {
            fontSize: "24px",
            width: "24px",
            height: "24px",
          },
          "&.MuiIcon-fontSizeLarge": {
            fontSize: "32px",
            width: "32px",
            height: "32px",
          },
        },
      },
    },
    MuiLink: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          color: "inherit",
        },
        ol: {
          flexWrap: "nowrap",
        },
        li: {
          minWidth: 0,
          flexGrow: 1,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      },
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          color: baseTheme.palette.action.active,
          padding: "10px",
          boxShadow: baseTheme.shadows[1],
          border: `1px solid ${baseTheme.palette.text.disabled}`,
          transform: "translate3d(0,0,0)",
        },
        arrow: {
          "&:before": {
            border: `1px solid ${baseTheme.palette.text.disabled}`,
          },
          fontSize: "14px",
          color: "white",
        },
      },
    },
    MuiLinearProgress: {
      defaultProps: {
        variant: "determinate",
      },
      styleOverrides: {
        root: {
          backgroundColor: lighten(baseTheme.palette.action.active, 0.9),
          borderRadius: 4,
          height: 8,
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          "& .MuiSwitch-track": {
            backgroundColor: "#ededed",
            opacity: 1,
          },
          "& .MuiSwitch-track.Mui-checked": {
            backgroundColor: "#a4c9ce",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          fontFamily: `${defaultFont}`,
          "& .MuiPaper-root": {
            maxHeight: "calc(100% - 64px)",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          fontFamily: `${defaultFont}`,
        },
      },
    },
  },
});

export const breakpointSx = (condition: string, sx: SxProps) => {
  let newCondition = condition;
  theme.breakpoints.keys.forEach((key, i) => {
    const next = theme.breakpoints.keys[i + 1];
    const start = theme.breakpoints.values[key] || 0;
    const end = next ? theme.breakpoints.values[next] : Number.MAX_SAFE_INTEGER;

    const up = `(min-width: ${start}px)`;
    const down = `(max-width: ${start}px)`;
    const only = `((min-width: ${start}px) and (max-width: ${end}px))`;

    newCondition = newCondition.replace(`${key}+`, up);
    newCondition = newCondition.replace(`${key}-`, down);
    newCondition = newCondition.replace(`${key}`, only);
  });

  const numPlusRegex = /(\d+)\+/g; // Matches digits followed by '+'
  const numMinusRegex = /(\d+)\-/g; // Matches digits followed by '-'
  newCondition = newCondition.replace(numPlusRegex, "(max-width: $1px)");
  newCondition = newCondition.replace(numMinusRegex, "(min-width: $1px)");

  return { [`@media only screen and (${newCondition})`]: sx };
};

export const mobileSx = (sx: SxProps) => breakpointSx("md-", sx);
export const desktopSx = (sx: SxProps) => breakpointSx("md+", sx);
