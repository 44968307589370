import { Box, Button, Typography } from "@mui/material";
import React, { ReactNode } from "react";

import { GradientBackground } from "@/components/GradientBackground";
import Image from "next/image";
import Link from "next/link";
import logo from "@/assets/logo.svg";
import signup from "@/assets/signup_intro.svg";

interface Props {
  title: string;
  body: ReactNode;
  button: { text: string; onClick: () => void };
}

const StatusComponent: React.FunctionComponentFactory<Props> = ({
  title,
  body,
  button,
}) => {
  return (
    <Box
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        zIndex: 1202, //Drawer is 1200, link modal is 1201
      }}
    >
      <GradientBackground>
        <Link href="/">
          <Box position="absolute" top="52px" left="52px">
            <Image src={logo} alt="" width={131} height={28} />
          </Box>
        </Link>
        <Box
          height="100%"
          minHeight="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Box>
            <Box mb="32px" mt="80px">
              <Image
                src={signup}
                alt="a crab surfing on a turtle as it waves to you"
                width={306}
                height={272}
              />
            </Box>
            <Typography variant="h1">{title}</Typography>
            <Box id="errorMessage" mt="40px">
              {body}
            </Box>
            <Button
              color="primary"
              variant="contained"
              onClick={button.onClick}
              sx={{ mt: "40px", mb: "40px" }}
            >
              {button.text}
            </Button>
          </Box>
        </Box>
      </GradientBackground>
    </Box>
  );
};

export default StatusComponent;
