import { useEffect, useRef } from "react";

import { AlertColor } from "@mui/material";
import { create } from "zustand";
import { getRandomMotivationQuote } from "@/libs/strings";

interface Toast {
  severity: AlertColor;
  message: string;
  open: boolean;
  icon: string;
  top: boolean;
}

interface NotificationsPopup {
  open: boolean;
}

interface TransitionStore {
  /** transitioning */
  transition: boolean;
  setTransition: (transition: boolean) => void;
  /** error / success toast */
  toast: Toast;
  setToast: (
    message: string,
    severity?: AlertColor,
    icon?: string,
    top?: boolean,
  ) => void;
  closeToast: () => void;
  displayMotivationalToast: () => void;

  /** notifications popup */
  notificationsPopup: NotificationsPopup;
  handleNotificationsClose: () => void;
  openNotificationsPopup: () => void;
}

export const useTransitionStore = create<TransitionStore>((set, get) => ({
  /** transitioning */
  transition: false,
  setTransition: (transition) => set({ transition }),

  /** error / success toast */
  toast: {
    severity: "error" as AlertColor,
    message: "",
    open: false,
    icon: "",
    top: false,
  },
  setToast: (
    message: string,
    severity: AlertColor = "error",
    icon?: string,
    top?: boolean,
  ) =>
    set({
      toast: {
        severity,
        message,
        open: true,
        icon: icon,
        top: top,
      },
    }),

  closeToast: () => set({ toast: { ...get().toast, open: false } }),
  displayMotivationalToast: () => {
    const motivation = getRandomMotivationQuote();
    const spaceLoc = motivation.indexOf(" ");
    get().setToast(
      motivation.substring(spaceLoc + 1),
      "info",
      motivation.substring(0, spaceLoc),
      true,
    );
  },

  /** notifications popup */
  notificationsPopup: {
    open: false,
  },
  handleNotificationsClose: () =>
    set({
      notificationsPopup: {
        open: false,
      },
    }),
  openNotificationsPopup: () => {
    set({
      notificationsPopup: {
        open: true,
      },
    });
  },
}));

export const useSetTransitioning = () => {
  const setTransitionRef = useRef(useTransitionStore.getState().setTransition);

  useEffect(() => {
    useTransitionStore.subscribe(
      (state) => (setTransitionRef.current = state.setTransition),
    );
  }, []);

  return setTransitionRef.current;
};

export const useSetToast = () => {
  const setToastRef = useRef(useTransitionStore.getState().setToast);

  useEffect(() => {
    useTransitionStore.subscribe(
      (state) => (setToastRef.current = state.setToast),
    );
  }, []);

  return setToastRef.current;
};

export const useCloseToast = () => {
  const closeToastRef = useRef(useTransitionStore.getState().closeToast);

  useEffect(() => {
    useTransitionStore.subscribe(
      (state) => (closeToastRef.current = state.closeToast),
    );
  }, []);

  return closeToastRef.current;
};

export const useDisplayMotivationalToast = () => {
  const displayMotivationalToastRef = useRef(
    useTransitionStore.getState().displayMotivationalToast,
  );

  useEffect(() => {
    useTransitionStore.subscribe(
      (state) =>
        (displayMotivationalToastRef.current = state.displayMotivationalToast),
    );
  }, []);

  return displayMotivationalToastRef.current;
};

export const useOpenNotificationsPopup = () => {
  const openNotificationsPopupRef = useRef(
    useTransitionStore.getState().openNotificationsPopup,
  );

  useEffect(() => {
    useTransitionStore.subscribe(
      (state) =>
        (openNotificationsPopupRef.current = state.openNotificationsPopup),
    );
  }, []);

  return openNotificationsPopupRef.current;
};

export const useHandleNotificationsClose = () => {
  const handleNotificationsCloseRef = useRef(
    useTransitionStore.getState().handleNotificationsClose,
  );

  useEffect(() => {
    useTransitionStore.subscribe(
      (state) =>
        (handleNotificationsCloseRef.current = state.handleNotificationsClose),
    );
  }, []);

  return handleNotificationsCloseRef.current;
};
